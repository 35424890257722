define("rose/components/rose/icon/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span ...attributes class="rose-icon {{@size}}">
    {{inline-svg @name}}
  </span>
  
  */
  {
    "id": "l3IUej54",
    "block": "[[[11,1],[17,1],[16,0,[29,[\"rose-icon \",[30,2]]]],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,3]],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@size\",\"@name\"],false,[\"inline-svg\"]]",
    "moduleName": "rose/components/rose/icon/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});