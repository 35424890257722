define("api/serializers/fragment-principal", ["exports", "@ember-data/serializer/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FragmentPrincipalSerializer extends _json.default {
    // =methods

    /**
     * Renames `id` to `principal_id` because fragments may not have IDs.
     * Otherwise normalizes as normal.
     * @param {ModelClass} typeClass
     * @param {object} resourceHash
     * @return {object}
     */
    normalize(typeClass, {
      id: principal_id,
      ...obj
    }) {
      return super.normalize(typeClass, {
        principal_id,
        ...obj
      });
    }

  }

  _exports.default = FragmentPrincipalSerializer;
});