define("rose/components/rose/badge/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span ...attributes
    class="rose-badge {{if  @style (concat "rose-badge-" @style)}}"
  >
    {{#if @icon}}
      <Rose::Icon
        @name={{@icon}}
        @size="medium"
      />
    {{/if}}
  
    <span class="rose-badge-body">
      {{yield}}
    </span>
  </span>
  
  */
  {
    "id": "41cJnWps",
    "block": "[[[11,1],[17,1],[16,0,[29,[\"rose-badge \",[52,[30,2],[28,[37,1],[\"rose-badge-\",[30,2]],null]]]]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,2],null,[[\"@name\",\"@size\"],[[30,3],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,1],[14,0,\"rose-badge-body\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@style\",\"@icon\",\"&default\"],false,[\"if\",\"concat\",\"rose/icon\",\"yield\"]]",
    "moduleName": "rose/components/rose/badge/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});