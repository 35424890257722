define("rose/components/rose/separator/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <hr class="rose-separator" />
  
  */
  {
    "id": "38Q5CrsF",
    "block": "[[[10,\"hr\"],[14,0,\"rose-separator\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "rose/components/rose/separator/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});