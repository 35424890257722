define("api/models/base", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model", "ember-data-model-fragments/attributes", "@ember/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _attributes, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  /**
   * Base model class provides common functionality for all models.
   *
   * One of the most interesting features of most resources is the
   * `scope` field, which arrives from the API as an embedded JSON fragment.
   * It contains information such as the parent scope ID and type.  This scope ID
   * is then used when generation scoped resource URLs via the
   * `adapterOptions.scopeID` option (see `save` method below).
   *
   * For convenience, developers may interact with scope in a number of ways.
   * The simplest method, when you don't care about other information, is to set
   * the `scopeID`, e.g. `record.scopeID = 'o_123'`.  The scope JSON fragment
   * may be set directly, `record.scope = { scope_id: 'o_123' }`.
   * For convenience, you may even set the scope using a `Scope` model:
   * `record.scopeModel = scopeModelInstance`, which automatically converts the
   * scope to a JSON fragment of the proper shape and assigns it to `scope`.
   *
   * A record's scope may be read in one of two ways:
   *
   *   1) As a JSON fragment:  `record.scope`,
   *   2) Or as an ID:  `record.scopeID`.
   */
  let BaseModel = (_dec = (0, _attributes.fragment)('fragment-scope'), _dec2 = (0, _model.attr)({
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec3 = (0, _model.attr)({
    readOnly: true
  }), _dec4 = (0, _object.computed)('scope.scope_id'), _dec5 = (0, _object.computed)('name', 'id'), _dec6 = (0, _object.computed)('hasDirtyAttributes', 'isSaving'), _dec7 = (0, _object.computed)('canSave'), (_class = class BaseModel extends _model.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "scope", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "authorized_actions", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "authorized_collection_actions", _descriptor3, this);
    }

    set scopeModel(model) {
      if (model) {
        const json = model.serialize();
        json.scope_id = model.id;
        this.scope = json;
      }
    }
    /**
     * Convenience for getting and setting the parent scope ID.
     * @type {string}
     */


    get scopeID() {
      var _this$scope;

      return this === null || this === void 0 ? void 0 : (_this$scope = this.scope) === null || _this$scope === void 0 ? void 0 : _this$scope.scope_id;
    }

    set scopeID(id) {
      if (!this.scope) this.scope = {};
      this.scope.scope_id = id;
    }
    /**
     * Names are optional on models in our API.  Thus we need to fallback on ID
     * for display purposes.
     * @type {string}
     */


    get displayName() {
      return this.name || this.id;
    }
    /**
     * @type {boolean}
     */


    get canSave() {
      return this.hasDirtyAttributes && !this.isSaving;
    }
    /**
     * @type {boolean}
     */


    get cannotSave() {
      return !this.canSave;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "scope", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "authorized_actions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "authorized_collection_actions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "scopeID", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "scopeID"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayName", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "displayName"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canSave", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "canSave"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cannotSave", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cannotSave"), _class.prototype)), _class));
  _exports.default = BaseModel;
});