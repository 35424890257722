define("rose/components/rose/form/textarea/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/component", "@ember/template-factory", "@glimmer/component", "rose/utilities/component-auto-id"], function (_exports, _defineProperty2, _component, _templateFactory, _component2, _componentAutoId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @contextual}}
    {{yield (hash
      label=(component 'rose/form/label'
        for=this.id
        error=@error
      )
      helperText=(component 'rose/form/helper-text'
        id=(concat 'helper-text-' this.id)
        error=@error
      )
      field=(component 'rose/form/textarea/textarea'
        id=this.id
        name=@name
        value=@value
        disabled=@disabled
        error=@error
        aria-describedby=(concat (if @helperText (concat 'helper-text-' this.id)) (if @error (concat ' errors-' this.id)))
      )
      errors=(component 'rose/form/errors'
        id=(concat 'errors-' this.id)
      )
    )}}
  {{else}}
    <div class="rose-form-input">
  
      <Rose::Form::Label @for={{this.id}} @error={{@error}}>
        {{@label}}
      </Rose::Form::Label>
  
      {{#if @helperText}}
        <Rose::Form::HelperText @id="helper-text-{{this.id}}" @error={{@error}}>
          {{@helperText}}
        </Rose::Form::HelperText>
      {{/if}}
  
      <Rose::Form::Textarea::Textarea
        ...attributes
        @id={{this.id}}
        @name={{@name}}
        @value={{@value}}
        @disabled={{@disabled}}
        @error={{@error}}
        @aria-describedby="{{if @helperText (concat 'helper-text-' this.id)}} {{if @error (concat 'errors-' this.id)}}" />
  
      {{yield (hash
        errors=(component 'rose/form/errors' id=(concat 'errors-' this.id))
      )}}
  
    </div>
  {{/if}}
  
  */
  {
    "id": "IXRPtum2",
    "block": "[[[41,[30,1],[[[1,\"  \"],[18,9,[[28,[37,2],null,[[\"label\",\"helperText\",\"field\",\"errors\"],[[50,\"rose/form/label\",0,null,[[\"for\",\"error\"],[[30,0,[\"id\"]],[30,2]]]],[50,\"rose/form/helper-text\",0,null,[[\"id\",\"error\"],[[28,[37,4],[\"helper-text-\",[30,0,[\"id\"]]],null],[30,2]]]],[50,\"rose/form/textarea/textarea\",0,null,[[\"id\",\"name\",\"value\",\"disabled\",\"error\",\"aria-describedby\"],[[30,0,[\"id\"]],[30,3],[30,4],[30,5],[30,2],[28,[37,4],[[52,[30,6],[28,[37,4],[\"helper-text-\",[30,0,[\"id\"]]],null]],[52,[30,2],[28,[37,4],[\" errors-\",[30,0,[\"id\"]]],null]]],null]]]],[50,\"rose/form/errors\",0,null,[[\"id\"],[[28,[37,4],[\"errors-\",[30,0,[\"id\"]]],null]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"rose-form-input\"],[12],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@for\",\"@error\"],[[30,0,[\"id\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,7]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,6],[[[1,\"      \"],[8,[39,6],null,[[\"@id\",\"@error\"],[[29,[\"helper-text-\",[30,0,[\"id\"]]]],[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,6]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,7],[[17,8]],[[\"@id\",\"@name\",\"@value\",\"@disabled\",\"@error\",\"@aria-describedby\"],[[30,0,[\"id\"]],[30,3],[30,4],[30,5],[30,2],[29,[[52,[30,6],[28,[37,4],[\"helper-text-\",[30,0,[\"id\"]]],null]],\" \",[52,[30,2],[28,[37,4],[\"errors-\",[30,0,[\"id\"]]],null]]]]]],null],[1,\"\\n\\n    \"],[18,9,[[28,[37,2],null,[[\"errors\"],[[50,\"rose/form/errors\",0,null,[[\"id\"],[[28,[37,4],[\"errors-\",[30,0,[\"id\"]]],null]]]]]]]]],[1,\"\\n\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@contextual\",\"@error\",\"@name\",\"@value\",\"@disabled\",\"@helperText\",\"@label\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"concat\",\"rose/form/label\",\"rose/form/helper-text\",\"rose/form/textarea/textarea\"]]",
    "moduleName": "rose/components/rose/form/textarea/index.hbs",
    "isStrictMode": false
  });

  class RoseFormTextareaComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "id", (0, _componentAutoId.generateComponentID)());
    }

  }

  _exports.default = RoseFormTextareaComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseFormTextareaComponent);
});