define("ember-loading/components/while-loading", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component", "ember-loading/templates/components/while-loading", "@ember/service"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _whileLoading, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  let WhileLoading = (_class = class WhileLoading extends _component.default {
    constructor(...args) {
      super(...args);
      this.layout = _whileLoading.default;
      this.tagName = '';
      (0, _initializerDefineProperty2.default)(this, "loading", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loading", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = WhileLoading;
  ;
});