define("ember-intl/-private/formatters/format-relative", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/debug", "fast-memoize", "intl-messageformat", "ember-intl/-private/error-types", "ember-intl/-private/formatters/-base"], function (_exports, _defineProperty2, _debug, _fastMemoize, _intlMessageformat, _errorTypes, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  const RELATIVE_TIME_OPTIONS = ['numeric', 'style', 'unit'];
  /**
   * @private
   * @hide
   */

  class FormatRelative extends _base.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "createNativeFormatter", (0, _fastMemoize.default)((locales, options) => {
        if (!Intl || !Intl.RelativeTimeFormat) {
          const error = new _intlMessageformat.FormatError(`Intl.RelativeTimeFormat is not available in this environment. Try polyfilling it using "@formatjs/intl-relativetimeformat"`, _errorTypes.MISSING_INTL_API);
          this.config.onError({
            kind: _errorTypes.MISSING_INTL_API,
            error
          });
          throw error;
        }

        return new Intl.RelativeTimeFormat(locales, options);
      }));
    }

    get options() {
      return RELATIVE_TIME_OPTIONS;
    }

    format(locale, value, formatOptions) {
      const formatterOptions = this.readOptions(formatOptions);
      this.validateFormatterOptions(locale, formatterOptions);
      const unit = (formatOptions === null || formatOptions === void 0 ? void 0 : formatOptions.unit) ?? formatterOptions.unit;
      (false && !(unit) && (0, _debug.assert)(`[ember-intl] FormatRelative: 'formatOptions' are missing a 'unit'.`, unit));
      const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
      return formatterInstance.format(typeof value === 'number' ? value : new Date(value).getTime(), unit);
    }

  }

  _exports.default = FormatRelative;
  (0, _defineProperty2.default)(FormatRelative, "type", 'relative');
});