define("rose/components/rose/footer/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <footer
    ...attributes
    class="rose-footer"
  >
    {{yield (hash
      nav=(component 'rose/footer/nav')
      brand=(component 'rose/footer/brand')
      text=(component "rose/anonymous" class="rose-footer-text")
    )}}
  </footer>
  
  */
  {
    "id": "NsTtbr6z",
    "block": "[[[11,\"footer\"],[17,1],[24,0,\"rose-footer\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"nav\",\"brand\",\"text\"],[[50,\"rose/footer/nav\",0,null,null],[50,\"rose/footer/brand\",0,null,null],[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-footer-text\"]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/footer/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});