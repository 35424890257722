define("api/models/fragment-scope", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data-model-fragments/fragment", "@ember/object", "@ember-data/model", "api/models/scope"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fragment, _object, _model, _scope) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  let FragmentScope = (_dec = (0, _model.attr)('string', {
    description: 'ID of the related scope'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'The type of the resource'
  }), _dec3 = (0, _object.computed)('type'), _dec4 = (0, _object.computed)('type'), _dec5 = (0, _object.computed)('type'), (_class = class FragmentScope extends _fragment.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "scope_id", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor2, this);
    }

    /**
     * @type {boolean}
     */
    get isGlobal() {
      return this.type === _scope.scopeTypes.global;
    }
    /**
     * @type {boolean}
     */


    get isOrg() {
      return this.type === _scope.scopeTypes.org;
    }
    /**
     * @type {boolean}
     */


    get isProject() {
      return this.type === _scope.scopeTypes.project;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "scope_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isGlobal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isGlobal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isOrg", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isOrg"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isProject", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isProject"), _class.prototype)), _class));
  _exports.default = FragmentScope;
});