define("rose/components/rose/header/brand/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    ...attributes
    class="rose-header-brand"
    title={{@text}}
    @route={{if @route @route "index"}}
  >
  
    {{#if @logo}}
      {{inline-svg @logo}}
    {{/if}}
  
    {{#if @text}}
      <h1 class="rose-header-brand-text">{{@text}}</h1>
    {{/if}}
  
  </LinkTo>
  
  */
  {
    "id": "FKtQzNkr",
    "block": "[[[8,[39,0],[[17,1],[24,0,\"rose-header-brand\"],[16,\"title\",[30,2]]],[[\"@route\"],[[52,[30,3],[30,3],\"index\"]]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[30,4],[[[1,\"    \"],[1,[28,[35,2],[[30,4]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"h1\"],[14,0,\"rose-header-brand-text\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@text\",\"@route\",\"@logo\"],false,[\"link-to\",\"if\",\"inline-svg\"]]",
    "moduleName": "rose/components/rose/header/brand/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});