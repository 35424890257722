define("api/models/host-set", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/host-set", "ember-data-model-fragments/attributes"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _hostSet, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let HostSetModel = (_dec = (0, _attributes.fragmentArray)('fragment-string', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), (_class = class HostSetModel extends _hostSet.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "host_ids", _descriptor, this);
    }

    // =methods

    /**
     * Adds hosts via the `add-hosts` method.
     * See serializer and adapter for more information.
     * @param {[string]} hostIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */
    addHosts(hostIDs, options = {
      adapterOptions: {}
    }) {
      const defaultAdapterOptions = {
        method: 'add-hosts',
        hostIDs
      };
      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Add a single host via the `add-hosts` method.
     * @param {string} hostID
     * @param {object} options
     * @return {Promise}
     */


    addHost(hostID, options) {
      return this.addHosts([hostID], options);
    }
    /**
     * Delete hosts via the `remove-hosts` method.
     * See serializer and adapter for more information.
     * @param {[string]} hostIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeHosts(hostIDs, options = {
      adapterOptions: {}
    }) {
      const defaultAdapterOptions = {
        method: 'remove-hosts',
        hostIDs
      };
      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single host via the `remove-hosts` method.
     * @param {string} hostID
     * @param {object} options
     * @return {Promise}
     */


    removeHost(hostID, options) {
      return this.removeHosts([hostID], options);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "host_ids", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HostSetModel;
});