define("rose/components/rose/footer/nav/link/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @href}}
    <a target="_blank"
      href={{@href}}
      class="rose-footer-nav-link"
      rel="noopener noreferrer" 
    >
      {{yield}}
    </a>
  {{/if}}
  
  {{#if @route}}
    <LinkTo
      ...attributes
      @route={{@route}}
      @model={{@model}}
      class="rose-footer-nav-link"
    >
      {{yield}}
    </LinkTo>
  {{/if}}
  
  */
  {
    "id": "Em6Q7ki2",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,3],[14,\"target\",\"_blank\"],[15,6,[30,1]],[14,0,\"rose-footer-nav-link\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"  \"],[8,[39,2],[[17,3],[24,0,\"rose-footer-nav-link\"]],[[\"@route\",\"@model\"],[[30,2],[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@href\",\"@route\",\"&attrs\",\"@model\",\"&default\"],false,[\"if\",\"yield\",\"link-to\"]]",
    "moduleName": "rose/components/rose/footer/nav/link/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});