define("api/models/host-catalog", ["exports", "api/generated/models/host-catalog"], function (_exports, _hostCatalog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HostCatalogModel extends _hostCatalog.default {
    /**
     * @type {boolean}
     */
    get isStatic() {
      return this.type === 'static';
    }

  }

  _exports.default = HostCatalogModel;
});