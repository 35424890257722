define("api/serializers/role", ["exports", "api/serializers/application", "@ember/object"], function (_exports, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RoleSerializer extends _application.default {
    // =methods

    /**
     * If `adapterOptions.serializeGrants` is true, the serialization should
     * include **only grants** and the version.  Normally, grants are not
     * serialized.
     * If `adapterOptions.principalIDs` is set (to an array of user and
     * group IDs), then the payload is serialized via `serializewithPrincipals`.
     * @override
     * @param {Snapshot} snapshot
     * @return {object}
     */
    serialize(snapshot) {
      var _snapshot$adapterOpti;

      const grantStrings = (0, _object.get)(snapshot, 'adapterOptions.grantStrings');
      let serialized = super.serialize(...arguments);
      if (grantStrings) serialized = this.serializeWithGrantStrings(snapshot, grantStrings);
      const principalIDs = snapshot === null || snapshot === void 0 ? void 0 : (_snapshot$adapterOpti = snapshot.adapterOptions) === null || _snapshot$adapterOpti === void 0 ? void 0 : _snapshot$adapterOpti.principalIDs;
      if (principalIDs) serialized = this.serializewithPrincipals(snapshot, principalIDs);
      return serialized;
    }
    /**
     * Returns a payload containing only the grants array.
     * @param {Snapshot} snapshot
     * @param {[string]} grantStrings
     * @return {object}
     */


    serializeWithGrantStrings(snapshot, grantStrings) {
      return {
        version: snapshot.attr('version'),
        grant_strings: grantStrings
      };
    }
    /**
     * Returns a payload containing only the principal_ids array using IDs
     * passed into the function (rather than existing principals on the model).
     * @param {Snapshot} snapshot
     * @param {[string]} principalIDs
     * @return {object}
     */


    serializewithPrincipals(snapshot, principalIDs) {
      return {
        version: snapshot.attr('version'),
        principal_ids: principalIDs
      };
    }

  }

  _exports.default = RoleSerializer;
});