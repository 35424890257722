define("api/models/user", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/user", "@ember-data/model", "@ember/object", "@ember/array"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _user, _model, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let UserModel = (_dec = (0, _model.attr)({
    readOnly: true,
    defaultValue: () => (0, _array.A)(),
    emptyArrayIfMissing: true
  }), _dec2 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec3 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec4 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec5 = (0, _object.computed)('email,full_name,login_name,displayName'), (_class = class UserModel extends _user.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "account_ids", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "email", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "full_name", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "login_name", _descriptor4, this);
    }

    /**
     * Convenience for getting a reasonable string that names the account.
     * There are many options, so we fallback in order of precedence.
     * @type {string}
     */
    get accountName() {
      const {
        email,
        full_name,
        login_name
      } = this;
      return email || full_name || login_name;
    } // =methods

    /**
     * Adds accounts via the `add-accounts` method.
     * See serializer and adapter for more information.
     * @param {[string]} accountIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addAccounts(accountIDs, options = {
      adapterOptions: {}
    }) {
      const defaultAdapterOptions = {
        method: 'add-accounts',
        accountIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Remove accounts via the `remove-accounts` method.
     * See serializer and adapter for more information.
     * @param {[string]} accountIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeAccounts(accountIDs, options = {
      adapterOptions: {}
    }) {
      const defaultAdapterOptions = {
        method: 'remove-accounts',
        accountIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Remove a single account via the `remove-accounts` method.
     * @param {number} accountID
     * @param {object} options
     * @return {Promise}
     */


    removeAccount(accountID, options) {
      return this.removeAccounts([accountID], options);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account_ids", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "email", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "full_name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "login_name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountName", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "accountName"), _class.prototype)), _class));
  _exports.default = UserModel;
});