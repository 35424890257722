define("rose/components/rose/form/helper-text/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class="rose-form-helper-text{{if @error " error"}}"
    id={{@id}}
    ...attributes
  >
    {{yield}}
  </span>
  
  */
  {
    "id": "RZVgo2d/",
    "block": "[[[11,1],[16,0,[29,[\"rose-form-helper-text\",[52,[30,1],\" error\"]]]],[16,1,[30,2]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@error\",\"@id\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "rose/components/rose/form/helper-text/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});