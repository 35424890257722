define("rose/components/rose/header/utilities/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="rose-header-utilities">
    {{yield (hash
      dropdown=(component 'rose/dropdown' dropdownRight=true)
    )}}
  </div>
  
  */
  {
    "id": "m3ZzD6TU",
    "block": "[[[10,0],[14,0,\"rose-header-utilities\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"dropdown\"],[[50,\"rose/dropdown\",0,null,[[\"dropdownRight\"],[true]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/header/utilities/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});