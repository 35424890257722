define("api/models/auth-method", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/auth-method", "@ember-data/model", "ember-data-model-fragments/attributes", "@ember/object/computed"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authMethod, _model, _attributes, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.options = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  /**
   * Enum options per auth method type and field.
   */
  const options = {
    oidc: {
      signing_algorithms: ['RS256', 'RS384', 'RS512', 'ES256', 'ES384', 'ES512', 'PS256', 'PS384', 'PS512', 'EdDSA'],
      state: ['inactive', 'active-private', 'active-public'],
      account_claim_maps: {
        to: ['sub', 'name', 'email']
      }
    }
  };
  _exports.options = options;
  let AuthMethodModel = (_dec = (0, _model.attr)('string', {
    readOnly: true
  }), _dec2 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec3 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec4 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec5 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec6 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec7 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec8 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec9 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec10 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec11 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec12 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec13 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec14 = (0, _attributes.fragment)('fragment-auth-method-attributes', {
    defaultValue: {}
  }), _dec15 = (0, _computed.equal)('is_primary', true), (_class = class AuthMethodModel extends _authMethod.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "attributes_state", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_issuer", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_client_id", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_client_secret", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_max_age", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_api_url_prefix", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_disable_discovered_config_validation", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_dry_run", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_account_claim_maps", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_claims_scopes", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_signing_algorithms", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_allowed_audiences", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_idp_ca_certs", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "attributes", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "isPrimary", _descriptor15, this);
    }

    /**
     * @type {boolean}
     */
    get isPassword() {
      return this.type === 'password';
    }
    /**
     * @type {boolean}
     */


    get isOIDC() {
      return this.type === 'oidc';
    }
    /**
     * @type {boolean}
     */


    get isInactive() {
      return this.attributes.state === 'inactive';
    }
    /**
     * @type {boolean}
     */


    get isPrivate() {
      return this.attributes.state === 'active-private';
    }
    /**
     * @type {boolean}
     */


    get isPublic() {
      return this.attributes.state === 'active-public';
    } // =methods

    /**
     * Change the active and visibility state of an OIDC auth method
     * given its ID.
     * For OIDC auth methods only.
     * @param {[string]} state
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    changeState(state, options = {
      adapterOptions: {}
    }) {
      const defaultAdapterOptions = {
        method: 'change-state',
        state
      };
      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_issuer", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_client_id", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_client_secret", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_max_age", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_api_url_prefix", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_disable_discovered_config_validation", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_dry_run", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_account_claim_maps", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_claims_scopes", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_signing_algorithms", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_allowed_audiences", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_idp_ca_certs", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isPrimary", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AuthMethodModel;
});