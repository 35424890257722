define("core/components/form/authenticate/password/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _templateFactory, _component2, _tracking, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Rose::Form
    class="full-width"
    @onSubmit={{fn this.submit @submit (hash
      identification=this.identification
      password=this.password
    )}}
    @cancel={{false}}
    @disabled={{@disabled}}
    as |form|
  >
  
    <form.input
      @name="identification"
      @type="text"
      @value={{this.identification}}
      @label={{t "form.login_name.label"}} />
  
    <form.input
      @name="password"
      @type="password"
      @value={{this.password}}
      @label={{t "form.password.label"}} />
  
    <form.actions
      @submitText={{t "actions.authenticate"}}
      @showCancel={{false}} />
  
  </Rose::Form>
  
  */
  {
    "id": "e1RLCyJk",
    "block": "[[[8,[39,0],[[24,0,\"full-width\"]],[[\"@onSubmit\",\"@cancel\",\"@disabled\"],[[28,[37,1],[[30,0,[\"submit\"]],[30,1],[28,[37,2],null,[[\"identification\",\"password\"],[[30,0,[\"identification\"]],[30,0,[\"password\"]]]]]],null],false,[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,3,[\"input\"]],null,[[\"@name\",\"@type\",\"@value\",\"@label\"],[\"identification\",\"text\",[30,0,[\"identification\"]],[28,[37,3],[\"form.login_name.label\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"input\"]],null,[[\"@name\",\"@type\",\"@value\",\"@label\"],[\"password\",\"password\",[30,0,[\"password\"]],[28,[37,3],[\"form.password.label\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"actions\"]],null,[[\"@submitText\",\"@showCancel\"],[[28,[37,3],[\"actions.authenticate\"],null],false]],null],[1,\"\\n\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@submit\",\"@disabled\",\"form\"],false,[\"rose/form\",\"fn\",\"hash\",\"t\"]]",
    "moduleName": "core/components/form/authenticate/password/index.hbs",
    "isStrictMode": false
  });

  let FormAuthenticatePasswordComponent = (_class = class FormAuthenticatePasswordComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "password", _descriptor, this);
    }

    // =methods

    /**
     * Unsets the password field.
     */
    resetPassword() {
      this.password = null;
    } // =actions

    /**
     * Submit credentials, but reset the password first in case of failure.
     * Callback with no arguments otherwise.
     * @param {function} fn
     * @param {object} creds
     */


    submit(fn, creds) {
      (0, _runloop.run)(() => this.resetPassword());
      (0, _runloop.run)(() => fn(creds));
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "password", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class);
  _exports.default = FormAuthenticatePasswordComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormAuthenticatePasswordComponent);
});