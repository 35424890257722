define("api/generated/models/managed-group", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  /**
   * A managed group is a resource that represents a collection of accounts.
   */
  let GeneratedManagedGroupModel = (_dec = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes.'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes.'
  }), _dec3 = (0, _model.attr)('date', {
    description: 'The time this resource was created',
    readOnly: true
  }), _dec4 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated.',
    readOnly: true
  }), _dec5 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), _dec6 = (0, _model.attr)('string', {
    description: 'The type of this ManagedGroup.'
  }), _dec7 = (0, _model.attr)('string', {
    description: 'The ID of the Auth Method that is associated with this ManagedGroup'
  }), (_class = class GeneratedManagedGroupModel extends _base.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "auth_method_id", _descriptor7, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "auth_method_id", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedManagedGroupModel;
});