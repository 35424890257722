define("api/models/credential-library", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/credential-library", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _credentialLibrary, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.options = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  /**
   * Enum options for credential library.
   */
  const options = {
    http_method: ['GET', 'POST']
  };
  _exports.options = options;
  let CredentialLibraryModel = (_dec = (0, _model.attr)('string', {
    readOnly: true
  }), _dec2 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec3 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec4 = (0, _attributes.fragment)('fragment-credential-library-attributes', {
    defaultValue: {}
  }), (_class = class CredentialLibraryModel extends _credentialLibrary.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "attributes_http_method", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_http_request_body", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_path", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "attributes", _descriptor4, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_http_method", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_http_request_body", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_path", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CredentialLibraryModel;
});