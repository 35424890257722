define("api/models/host", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/host", "ember-data-model-fragments/attributes"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _host, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let HostModel = (_dec = (0, _attributes.fragment)('fragment-host-attributes', {
    defaultValue: {}
  }), (_class = class HostModel extends _host.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "attributes", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HostModel;
});