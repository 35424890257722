define("api/models/fragment-host-source", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let FragmentHostSourceModel = (_dec = (0, _model.attr)('string', {
    description: 'ID of the related host source'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'ID of the catalog to which the host set belongs'
  }), (_class = class FragmentHostSourceModel extends _fragment.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "host_source_id", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "host_catalog_id", _descriptor2, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "host_source_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "host_catalog_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FragmentHostSourceModel;
});