define("api/serializers/user", ["exports", "api/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserSerializer extends _application.default {
    // =methods

    /**
     * If `adapterOptions.accountIDs` is set (to an array of user IDs),
     * then the payload is serialized via `serializewithAccounts`.
     * @override
     * @param {Snapshot} snapshot
     * @return {object}
     */
    serialize(snapshot) {
      var _snapshot$adapterOpti;

      let serialized = super.serialize(...arguments);
      const accountIDs = snapshot === null || snapshot === void 0 ? void 0 : (_snapshot$adapterOpti = snapshot.adapterOptions) === null || _snapshot$adapterOpti === void 0 ? void 0 : _snapshot$adapterOpti.accountIDs;
      if (accountIDs) serialized = this.serializewithAccounts(snapshot, accountIDs);
      return serialized;
    }
    /**
     * Returns a payload containing only the `account_ids` array (and version).
     * @param {Snapshot} snapshot
     * @param {[string]} account_ids
     * @return {object}
     */


    serializewithAccounts(snapshot, account_ids) {
      return {
        version: snapshot.attr('version'),
        account_ids
      };
    }

  }

  _exports.default = UserSerializer;
});