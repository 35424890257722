define("core/components/doc-link/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    class="doc-link"
    href={{doc-url @doc}}
    target="_blank"
    rel="noreferrer noopener"
    title={{t "actions.get-topic-help"}}>
    <Rose::Icon @name="flight-icons/svg/help-24" @size={{@iconSize}} />
  </a>
  
  */
  {
    "id": "1UjEPKb4",
    "block": "[[[10,3],[14,0,\"doc-link\"],[15,6,[28,[37,0],[[30,1]],null]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noreferrer noopener\"],[15,\"title\",[28,[37,1],[\"actions.get-topic-help\"],null]],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@name\",\"@size\"],[\"flight-icons/svg/help-24\",[30,2]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@doc\",\"@iconSize\"],false,[\"doc-url\",\"t\",\"rose/icon\"]]",
    "moduleName": "core/components/doc-link/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});