define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "actions": {
      "add": "Add",
      "add-host": "Create Host",
      "add-host-set": "Create Host Set",
      "add-hosts": "Add Hosts",
      "add-members": "Add Members",
      "add-principals": "Add Principals",
      "authenticate": "Authenticate",
      "back": "Back",
      "cancel": "Cancel",
      "change-origin": "Change Origin URL",
      "change-password": "Change Password",
      "change-state": "Change State",
      "choose-different-org": "Choose a different scope",
      "choose-org": "Choose Scope",
      "clear-all-filters": "Clear all filters",
      "close": "Close",
      "confirm": "Confirm",
      "copy-error-detail-to-clipboard": "Copy error detail to clipboard",
      "copy-to-clipboard": "Copy to Clipboard",
      "deauthenticate": "Deauthenticate",
      "delete": "Delete",
      "deny": "Deny",
      "discard": "Discard",
      "disconnect": "Disconnect",
      "dismiss": "Dismiss",
      "edit-form": "Edit Form",
      "fullscreen": "Fullscreen",
      "get-topic-help": "Get help for this topic",
      "help": "Need help?",
      "manage": "Manage",
      "minimize": "Minimize",
      "ok": "OK",
      "remove": "Remove",
      "retry": "Retry",
      "save": "Save",
      "set-password": "Set Password",
      "submit": "Submit",
      "toggle-secret": "Toggle secret visibility"
    },
    "auth": {
      "descriptions": {
        "authenticating-with-org": "Scope:  You are authenticating with organization \"{name}\"",
        "choose-org": "Choose a scope to authenticate.",
        "flow-error": "Something went wrong while authenticating.  Please close this window and try again.  If the problem persists, notify your administrator.",
        "flow-success": "You've successfully authenticated.  You may now close this window and return to the client.",
        "no-scopes": "No scopes were found.  To authenticate, ask your administrator to create an scope and grant you access."
      },
      "titles": {
        "flow-error": "Could not authenticate",
        "flow-success": "Successful authentication",
        "no-scopes": "No Scopes Available"
      }
    },
    "descriptions": {
      "create-but-not-list": "You do not have permission to list {resource}, but you can create new instances.",
      "empty-set": "There are no items to display yet.  You may be able to add items or try back later.",
      "neither-list-nor-create": "You do not have permission to list or create {resource}.",
      "no-results": "We found no matching records.",
      "origin-initialization": "To get started, please enter your origin URL"
    },
    "errors": {
      "401": {
        "description": "You are not authenticated. Please authenticate and try again later.",
        "title": "You are not signed in"
      },
      "403": {
        "description": "You must be granted permissions to view this data. Ask your administrator if you think you should have access.",
        "title": "You are not authorized"
      },
      "404": {
        "description": "We could not find the requested resource. You can ask your administrator or try again later.",
        "title": "Resource not found"
      },
      "500": {
        "description": "We ran into a problem and could not continue. You can ask your administrator or try again later.",
        "title": "Server error"
      },
      "authentication-failed": {
        "title": "Authentication Failed"
      },
      "generic": {
        "title": "Error"
      },
      "origin-verification-failed": {
        "description": "Unable to connect to the specified origin. Please check the URL and try again."
      },
      "unknown": {
        "description": "We're not sure what happened.  Please contact your administrator or try again later.",
        "title": "Something went wrong"
      }
    },
    "form": {
      "account_claim_map": {
        "label": "Account Claim Map"
      },
      "account_claim_maps": {
        "help": "Optional claim maps from custom claims to the standard claims of sub, name and email.  These maps are represented as key=value where the key equals the from_claim and the value equals the to_claim.  For example \"oid=sub\".\n",
        "label": "Account Claim Maps"
      },
      "address": {
        "help": "An IP, host name, or other routable address",
        "label": "Address"
      },
      "algorithm": {
        "label": "Algorithm"
      },
      "allowed_audiences": {
        "help": "Allowed audience claims for this auth method.",
        "label": "Allowed Audiences"
      },
      "api_url_prefix": {
        "help": "The prefix that should be used for any URLs needed during the authentication flow. This includes the callback URL, the token retrieval URL, and the redirection URL used by the OIDC Authorization Server.",
        "label": "API URL Prefix"
      },
      "callback_url": {
        "help": "The URL to which the third-party provider sends the authentication response.  Paste this into the provider configuration.",
        "label": "Callback URL"
      },
      "certificate": {
        "label": "Certificate"
      },
      "claim": {
        "label": "Claim"
      },
      "claims_scope": {
        "label": "Claims Scope"
      },
      "claims_scopes": {
        "help": "Optional claims scopes that will be requested during authentication.",
        "label": "Claims Scopes"
      },
      "client_id": {
        "help": "An OAuth 2.0 Client Identifier valid at the Authorization Server.",
        "label": "Client ID"
      },
      "client_secret": {
        "help": "The client secret is set-only and may not be unset.",
        "label": "Client Secret"
      },
      "client_secret_hmac": {
        "label": "Client Secret HMAC"
      },
      "current_password": {
        "label": "Current Password"
      },
      "default_port": {
        "help": "The default port on which to connect",
        "label": "Default Port"
      },
      "description": {
        "help": "Description for identification purposes",
        "label": "Description"
      },
      "disable_discovered_config_validation": {
        "help": "If the Authorization Server's discovered configuration contains values that do not match the configuration set on this auth method, this can be set to force the local configuration to override the discovered values.  This is useful in the case of slow-changing or incorrect values announced by the Authorization Server. NOTE: For safety, not providing this value will be treated the same as \"false\"; that is, every time the method is updated or the state is changed, this value must be set to \"true\" or it will be disabled.\n",
        "label": "Disable discovered config validation"
      },
      "email": {
        "label": "Email"
      },
      "filter": {
        "help": "A boolean expression defining a filter run against the provided information.",
        "label": "Filter"
      },
      "from_claim": {
        "label": "From Claim"
      },
      "full_name": {
        "label": "Full Name"
      },
      "grant": {
        "help": "A permissions grant string for a role",
        "label": "Grant"
      },
      "grant_scope": {
        "help": "Specify the scope to which this role applies.",
        "label": "Grant Scope"
      },
      "host_set": {
        "label": "Host Set"
      },
      "id": {
        "label": "ID"
      },
      "idp_ca_certs": {
        "help": "Optional PEM-encoded X.509 CA certificates that can be used as trust anchors when connecting to an OIDC provider.",
        "label": "Certificates"
      },
      "issuer": {
        "help": "The issuer URL. Boundary expects only the schema, host, and port and will strip off \".well-known/openid-configuration\" if present. This will be used for configuration discovery as well as for validation of the \"iss\" claim.",
        "label": "Issuer"
      },
      "key": {
        "label": "Key"
      },
      "login_name": {
        "label": "Login Name"
      },
      "max_age": {
        "help": "The OIDC \"max_age\" parameter sent to the Authorization Server indicating a maximum acceptable time in seconds since the user's last authentication before requiring the user to reauthenticate. 0 indicates an immediate need to reauthenticate.",
        "label": "Maximum Age"
      },
      "name": {
        "help": "Name for identification purposes",
        "label": "Name"
      },
      "new_password": {
        "label": "New Password"
      },
      "origin": {
        "description": "The Boundary URL to which to connect (e.g. protocol://host:port)",
        "label": "Origin URL"
      },
      "password": {
        "label": "Password"
      },
      "proxy": {
        "label": "Proxy"
      },
      "session_connection_limit": {
        "help": "The maximum number of connections allowed per session.  For unlimited, specify \"-1\".",
        "label": "Maximum Connections"
      },
      "session_max_seconds": {
        "help": "The maximum duration of a session.",
        "label": "Maximum Duration (in seconds)"
      },
      "set_password": {
        "help": "Create a new password",
        "label": "Password"
      },
      "signing_algorithms": {
        "help": "The signing algorithms allowed for the auth method.",
        "label": "Signing Algorithms"
      },
      "started": {
        "label": "Started"
      },
      "status": {
        "label": "Status"
      },
      "subject": {
        "label": "Subject"
      },
      "to_claim": {
        "label": "To Claim"
      },
      "type": {
        "help": "The type of the resource, to help differentiate schemas",
        "label": "Type"
      },
      "username": {
        "label": "Username"
      },
      "value": {
        "label": "Value"
      }
    },
    "notifications": {
      "add-success": "Added successfully.",
      "canceled-success": "Canceled successfully.",
      "create-success": "Created successfully.",
      "delete-success": "Deleted successfully.",
      "remove-success": "Removed successfully.",
      "save-success": "Saved successfully."
    },
    "origin": {
      "connected-to": "Connected to"
    },
    "questions": {
      "abandon-confirm": "Are you sure you want to navigate away?  Your unsaved changes will be lost.",
      "delete-confirm": "Are you sure you want to delete this resource?",
      "remove-confirm": "Are you sure you want to remove this association?",
      "retry-confirm": "Would you like to retry?"
    },
    "resources": {
      "account": {
        "actions": {
          "create": "Create Account",
          "delete": "Delete Account"
        },
        "description": "An account allows a user to log into Boundary with a particular auth method. Users may have multiple accounts for different auth methods.",
        "messages": {
          "set-password": {
            "description": "Set a password for the account."
          },
          "welcome": {
            "description": "Create and manage accounts for an auth method.",
            "title": "Welcome to Accounts"
          }
        },
        "title": "Account",
        "title_plural": "Accounts",
        "types": {
          "oidc": "OIDC",
          "password": "Password"
        }
      },
      "auth-method": {
        "actions": {
          "create": "Create Auth Method",
          "delete": "Delete Auth Method",
          "make-primary": "Make Primary",
          "remove-as-primary": "Remove as Primary"
        },
        "description": "Auth methods allow users to authenticate within a scope.",
        "messages": {
          "no-primary-auth-method": {
            "description": "A primary auth method auto-creates users when they authenticate for the first time.  Scopes without primary auth methods may experience authentication failures.",
            "title": "Recommendation: Select a Primary Auth Method"
          },
          "none": {
            "description": "No auth methods were found for this scope.",
            "title": "No Auth Methods"
          },
          "pending": {
            "description": "Complete authentication in the newly-opened window.",
            "title": "Authentication Pending"
          },
          "welcome": {
            "description": "Auth methods are configurations to authenticate users.",
            "title": "Welcome to Auth Methods"
          }
        },
        "notifications": {
          "make-primary-success": "Auth method successfully elected to primary.",
          "remove-as-primary-success": "Auth method successfully removed as primary."
        },
        "questions": {
          "make-primary": "Make primary auth method?",
          "make-primary-confirm": "A primary auth method auto-creates users on their first authentication attempt if needed.  Note:  if you change the primary auth method, the previous primary will no longer auto-create users.",
          "no-see-window": "Don't see the new window?",
          "remove-as-primary": "Remove primary auth method?",
          "remove-as-primary-confirm": "Removing the primary auth method may lead to authentication failures for this scope."
        },
        "title": "Auth Method",
        "title_plural": "Auth Methods",
        "titles": {
          "new-allowed-audience": "New Allowed Audience",
          "new-certificate": "New Certificate",
          "new-claims-scope": "New Claims Scope",
          "new-from-claim": "New From Claim",
          "new-signing-algorithm": "New Signing Algorithm",
          "new-to-claim": "New To Claim"
        },
        "types": {
          "oidc": "OIDC",
          "password": "Password"
        }
      },
      "credential-library": {
        "actions": {
          "create": "New Credential Library",
          "delete": "Delete Credential Library"
        },
        "description": "A credential library is a resource that provides credentials.",
        "form": {
          "http_method": {
            "help": "The HTTP method the library uses when requesting credentials from Vault.",
            "label": "HTTP Method"
          },
          "http_request_body": {
            "help": "The body of the HTTP request the library sends to Vault when requesting credentials. Only allowed when HTTP method is set to POST.",
            "label": "HTTP Method POST Request Body"
          },
          "vault_path": {
            "help": "The path the library uses when requesting credentials from Vault.",
            "label": "Vault Path"
          }
        },
        "messages": {
          "none": {
            "description": "No Credential Libraries available in this credential store.",
            "title": "No Credential Libraries Available"
          }
        },
        "title": "Credential Library",
        "title_plural": "Credential Libraries",
        "types": {
          "vault": "Vault"
        }
      },
      "credential-store": {
        "actions": {
          "delete": "Delete Credential Store"
        },
        "description": "A credential store is a collection of credentials and credential libraries.",
        "form": {
          "address": {
            "help": "The address of the Vault server. This should be a complete URL such as https://127.0.0.1:8200.",
            "label": "Address"
          },
          "ca_cert": {
            "help": "A PEM-encoded CA certificate to verify the Vault server's TLS certificate.",
            "label": "CA Certificate"
          },
          "client_certificate": {
            "help": "A PEM-encoded client certificate to use for TLS authentication to the Vault server.",
            "label": "Client Certificate"
          },
          "client_certificate_key": {
            "help": "A PEM-encoded private key matching the client certificate from Client Certificate.",
            "label": "Client Certificate Key"
          },
          "client_certificate_key_hmac": {
            "label": "Client Certificate Key HMAC"
          },
          "namespace": {
            "help": "A Vault namespace. Requires Vault Enterprise.",
            "label": "Namespace"
          },
          "tls_server_name": {
            "help": "Name to use as the SNI host when connecting to Vault via TLS.",
            "label": "TLS Server Name"
          },
          "tls_skip_verify": {
            "help": "Disable verification of TLS certificates. Using this option is highly discouraged as it decreases the security of data transmissions to and from the Vault server.",
            "label": "TLS Skip Verify"
          },
          "token": {
            "help": "A token used for accessing Vault.",
            "label": "Token"
          },
          "token_hmac": {
            "label": "Token HMAC"
          }
        },
        "messages": {
          "welcome": {
            "title": "Welcome to Credential Stores"
          }
        },
        "title": "Credential Store",
        "title_plural": "Credential Stores",
        "types": {
          "vault": "Vault"
        }
      },
      "group": {
        "actions": {
          "add-members": "Add Members",
          "delete": "Delete Group"
        },
        "description": "Groups are collections of users. Groups may be assigned to roles to receive the role’s grants.",
        "messages": {
          "add-members": {
            "description": "Select users to add users to this group.",
            "title": "Add Members"
          },
          "members": {
            "description": "Members are the users assigned to a group.",
            "title": "Members"
          },
          "no-members": {
            "description": "There are no members available to add to this group.",
            "title": "No Members Available"
          },
          "welcome": {
            "title": "Welcome to Groups"
          }
        },
        "title": "Group",
        "title_plural": "Groups"
      },
      "host": {
        "actions": {
          "add": "Add Host",
          "create": "New Host",
          "delete": "Delete Host"
        },
        "description": "A host is a resource that may be accessed by a Boundary target.",
        "messages": {
          "none": {
            "description": "No hosts available in this scope.",
            "title": "No Hosts"
          },
          "none-friendly": {
            "description": "No hosts available to display yet.",
            "title": "No Hosts Available"
          },
          "welcome": {
            "title": "Welcome to Hosts"
          }
        },
        "title": "Host",
        "title_plural": "Hosts",
        "types": {
          "static": "Static"
        }
      },
      "host-catalog": {
        "actions": {
          "delete": "Delete Host Catalog"
        },
        "description": "A host catalog is a collection of hosts and host sets.",
        "messages": {
          "welcome": {
            "title": "Welcome to Host Catalogs"
          }
        },
        "title": "Host Catalog",
        "title_plural": "Host Catalogs",
        "types": {
          "static": "Static"
        }
      },
      "host-set": {
        "actions": {
          "add": "Add Host Set",
          "add-hosts": "Add Existing Host",
          "create": "New Host Set",
          "create-and-add-host": "Create and Add Host",
          "delete": "Delete Host Set"
        },
        "description": "A host set is a collection of hosts within a host catalog.",
        "host": {
          "messages": {
            "add": {
              "description": "Select hosts that belong to this host set.",
              "title": "Add Hosts"
            },
            "add-none": {
              "description": "No hosts available for selection.",
              "title": "No Hosts Available"
            },
            "none": {
              "description": "No hosts available in this host set.",
              "title": "No Hosts"
            }
          }
        },
        "messages": {
          "welcome": {
            "title": "Welcome to Host Sets"
          }
        },
        "title": "Host Set",
        "title_plural": "Host Sets",
        "types": {
          "static": "Static"
        }
      },
      "managed-group": {
        "actions": {
          "create": "Create Managed Group",
          "delete": "Delete Managed Group"
        },
        "description": "A managed group represents a collection of accounts.",
        "member": {
          "messages": {
            "description": "No members in this managed group.",
            "title": "Welcome to Members"
          },
          "title": "Member",
          "title_plural": "Members"
        },
        "messages": {
          "welcome": {
            "description": "Create and manage managed groups for an auth method.",
            "title": "Welcome to Managed Groups"
          }
        },
        "title": "Managed Group",
        "title_plural": "Managed Groups"
      },
      "org": {
        "actions": {
          "delete": "Delete Org"
        },
        "description": "An org is a type of scope used to organize projects and IAM resources.  Orgs are child scopes of the global scope.",
        "messages": {
          "settings": {
            "title": "Org Settings"
          },
          "welcome": {
            "title": "Welcome to Orgs"
          }
        },
        "title": "Org",
        "title_plural": "Orgs"
      },
      "project": {
        "actions": {
          "delete": "Delete Project"
        },
        "description": "A project is a type of scope used to organize resources such as targets and host catalogs.",
        "messages": {
          "settings": {
            "title": "Project Settings"
          },
          "welcome": {
            "title": "Welcome to Projects"
          }
        },
        "title": "Project",
        "title_plural": "Projects"
      },
      "role": {
        "actions": {
          "create": "Create Role",
          "delete": "Delete Role"
        },
        "description": "Roles are collections of capability grants and the principals (users and groups) assigned to them.",
        "grant": {
          "actions": {
            "create": "New Grant"
          },
          "description": "Grants are permissions which allow roles to take actions and access resources.",
          "title": "Grant",
          "title_plural": "Grants"
        },
        "messages": {
          "welcome": {
            "title": "Welcome to Roles"
          }
        },
        "principal": {
          "actions": {
            "add-principals": "Add Principals"
          },
          "description": "Principals are the users and groups assigned to a role and bounded by a role’s grants.",
          "messages": {
            "add-principals": {
              "description": "Select users and groups to assign to this role.",
              "title": "Add Principals"
            },
            "none": {
              "description": "Principals are not available for assignment.",
              "title": "No Principals Available"
            },
            "welcome": {
              "description": "Principals are not assigned in this role.",
              "title": "Welcome to Principals"
            }
          },
          "title": "Principal",
          "title_plural": "Principals",
          "types": {
            "group": "Group",
            "user": "User"
          }
        },
        "title": "Role",
        "title_plural": "Roles"
      },
      "scope": {
        "messages": {
          "cannot_read": "While you do not have permission to read this scope, you may have permission to view resources within it."
        },
        "title": "Scope",
        "title_plural": "Scopes"
      },
      "session": {
        "actions": {
          "connect": "Connect"
        },
        "active_plural": "Active Sessions",
        "credential": {
          "actions": {
            "raw-api": {
              "hide": "Hide raw API output",
              "show": "View raw API output"
            }
          },
          "credential_library": {
            "title": "Credential Library"
          },
          "secret": {
            "label": "Secret"
          },
          "title": "Credential",
          "title_plural": "Credentials"
        },
        "description": "A session is a connection to a target initiated through Boundary.",
        "messages": {
          "none": {
            "description": "There are no active sessions within the current scope.",
            "title": "No Sessions"
          },
          "none-active-friendly": {
            "description": "No active sessions available to display yet.",
            "title": "No Active Sessions Available"
          },
          "none-friendly": {
            "description": "No sessions available to display yet.",
            "title": "No Sessions Available"
          }
        },
        "status": {
          "active": "Active",
          "canceling": "Canceling",
          "pending": "Pending",
          "terminated": "Terminated"
        },
        "title": "Session",
        "title_plural": "Sessions"
      },
      "target": {
        "actions": {
          "add-credential-sources": "Add Credential Sources",
          "add-host-sources": "Add Host Sources",
          "create": "New Target",
          "delete": "Delete Target"
        },
        "credential-source": {
          "messages": {
            "add": {
              "description": "Select credential sources to assign to this target.",
              "title": "Add Credential Sources"
            },
            "none": {
              "description": "No credential sources available to add to this target.",
              "title": "No Credential Sources Available"
            },
            "welcome": {
              "description": "No credential sources in this target.",
              "title": "Welcome to Credential Sources"
            }
          },
          "title": "Credential Source",
          "title_plural": "Credential Sources"
        },
        "description": "A target is a logical collection of host sets which may be used to initiate sessions.",
        "host-source": {
          "messages": {
            "add": {
              "description": "Select host sources to assign to this target.",
              "title": "Add Host Sources"
            },
            "none": {
              "description": "No hosts sources available to add to target.",
              "title": "No Host Sources Available"
            },
            "welcome": {
              "description": "No hosts sources in this target.",
              "title": "Welcome to Host Sources"
            }
          },
          "title": "Host Source",
          "title_plural": "Host Sources"
        },
        "messages": {
          "connection-details": {
            "title": "Target Connection Details"
          },
          "connection-success": {
            "title": "Successfully Connected"
          },
          "none": {
            "description": "No Targets to display yet. Contact your Boundary admin if you don't see a Target you expect access to.",
            "title": "No Targets Available"
          },
          "proxy_url": {
            "title": "Proxy URL"
          },
          "welcome": {
            "title": "Welcome to Targets"
          }
        },
        "title": "Target",
        "title_plural": "Targets",
        "types": {
          "tcp": "TCP"
        }
      },
      "user": {
        "actions": {
          "add-accounts": "Add Accounts",
          "delete": "Delete User"
        },
        "description": "Users are entities authorized to access Boundary.  Users may be assigned to roles as principals, thus receiving role grants.",
        "messages": {
          "accounts": {
            "description": "Users may be associated with any number of accounts.",
            "title": "Accounts"
          },
          "add-accounts": {
            "description": "Select accounts to associate with this user.",
            "title": "Add Accounts"
          },
          "no-accounts": {
            "description": "There are no accounts available to add to this user.",
            "title": "No Accounts Available"
          },
          "welcome": {
            "title": "Welcome to Users"
          }
        },
        "title": "User",
        "title_plural": "Users"
      }
    },
    "states": {
      "active": "Active",
      "active-private": "Private",
      "active-public": "Public",
      "copied": "Copied",
      "error": "Error",
      "inactive": "Inactive",
      "info": "Info",
      "primary": "Primary",
      "success": "Success",
      "warning": "Warning"
    },
    "themes": {
      "dark": "Dark",
      "light": "Light",
      "system": "System Default"
    },
    "titles": {
      "abandon-confirm": "Discard unsaved changes?",
      "actions": "Actions",
      "details": "Details",
      "documentation": "Documentation",
      "empty-set": "No Items Yet",
      "general": "General",
      "global": "Global",
      "home": "Home",
      "iam": "IAM",
      "label": "Label",
      "new": "New",
      "no-results": "No Results",
      "overview": "Overview",
      "resources": "Resources",
      "settings": "Settings",
      "toggle-color-theme": "Toggle Color Theme",
      "value": "Value",
      "welcome-to-boundary": "Welcome to Boundary"
    }
  }]];
  _exports.default = _default;
});