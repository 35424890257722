define("auth/authenticators/password", ["exports", "auth/authenticators/base", "rsvp", "fetch"], function (_exports, _base, _rsvp, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * This authenticator is intended for use with Ember Simple Auth.
   *
   * Simple id/password base authenticator sends credentials to an endpoint
   * specified by the `buildAuthEndpointURL`.  If the HTTP response code is in the
   * success range, authentication resolves.  Otherwise it rejects.
   *
   * Upon session invalidation, deauthentication is attempted at the URL generated
   * by `buildDeauthEndpointURL`, but is not guaranteed.
   *
   * When a session is restored, a call is made to the endpoint specified by
   * `buildTokenValidationEndpointURL`.  If this endpoint responds with 401 or
   * 404, then the session is invalid and the restoration is rejected.  All other
   * responses will resolve the session restoration successfully.
   *
   * This authenticator should not be used directly because it does not specify
   * an `buildAuthEndpointURL`, `buildDeauthEndpointURL`, or
   * `buildTokenValidationEndpointURL` of its own.
   * To use, generate an application authenticator in your app
   * `authenticators/application.js` and extend this class.
   *
   * @example
   *
   *   import PasswordAuthenticator from 'auth/authenticators/password';
   *   export default class ApplicationAuthenticator extends PasswordAuthenticator {
   *     authEndpoint = '/api/authenticate';
   *     deauthEndpoint = '/api/deauthenticate';
   *   }
   *
   */
  class PasswordAuthenticator extends _base.default {
    // =methods

    /**
     * Posts credentials to the URL specified in `authEndpoint` and resolves
     * if a success HTTP status code is received, otherwise rejects.
     *
     * If requesting cookies, be sure to use the cookie session store.  Otherwise
     * use the local storage session store.
     *
     * @override
     * @param {object} creds
     * @param {string} creds.identification is sent to server as `login_name`
     * @param {string} creds.password
     * @param {boolean} requestCookies request cookie tokens (default `true`)
     * @return {Promise}
     */
    async authenticate({
      identification: login_name,
      password
    }, requestCookies = true, options) {
      const body = JSON.stringify({
        command: 'login',
        token_type: requestCookies ? 'cookie' : null,
        attributes: {
          login_name,
          password
        }
      });
      const authEndpointURL = this.buildAuthEndpointURL(options);
      const response = await (0, _fetch.default)(authEndpointURL, {
        method: 'post',
        body
      });
      const json = await response.json();
      return response.status < 400 ? (0, _rsvp.resolve)(this.normalizeData(json, login_name)) : (0, _rsvp.reject)();
    }

  }

  _exports.default = PasswordAuthenticator;
});