define("rose/components/rose/notification/index", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _applyDecoratedDescriptor2, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section
    ...attributes
    class="rose-notification is-{{@style}}{{if @dismiss " is-dismissible"}}"
    role="alert"
    aria-live="{{if @dismiss "assertive"}}"
    aria-atomic="true"
  >
  
    <header class="rose-notification-header">
      <Rose::Icon
        class="rose-notification-icon"
        @name={{this.icon}}
        @size="medium" />
      {{@heading}}
    </header>
  
    <div class="rose-notification-body">
      {{yield}}
    </div>
  
    {{#if @dismiss}}
      <button
        type="button"
        class="rose-notification-dismiss"
        title={{@dismissText}}
        {{on "click" @dismiss}}
      >
        <Rose::Icon
          @name="flight-icons/svg/x-16"
          @size="medium" />
        <span class="rose-notification-dismiss-text">
          {{@dismissText}}
        </span>
      </button>
    {{/if}}
  
  </section>
  
  */
  {
    "id": "Vn+I9R9A",
    "block": "[[[11,\"section\"],[17,1],[16,0,[29,[\"rose-notification is-\",[30,2],[52,[30,3],\" is-dismissible\"]]]],[24,\"role\",\"alert\"],[16,\"aria-live\",[29,[[52,[30,3],\"assertive\"]]]],[24,\"aria-atomic\",\"true\"],[12],[1,\"\\n\\n  \"],[10,\"header\"],[14,0,\"rose-notification-header\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"rose-notification-icon\"]],[[\"@name\",\"@size\"],[[30,0,[\"icon\"]],\"medium\"]],null],[1,\"\\n    \"],[1,[30,4]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"rose-notification-body\"],[12],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[11,\"button\"],[24,0,\"rose-notification-dismiss\"],[16,\"title\",[30,5]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,3]],null],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@size\"],[\"flight-icons/svg/x-16\",\"medium\"]],null],[1,\"\\n      \"],[10,1],[14,0,\"rose-notification-dismiss-text\"],[12],[1,\"\\n        \"],[1,[30,5]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@style\",\"@dismiss\",\"@heading\",\"@dismissText\",\"&default\"],false,[\"if\",\"rose/icon\",\"yield\",\"on\"]]",
    "moduleName": "rose/components/rose/notification/index.hbs",
    "isStrictMode": false
  });

  let RoseNotificationComponent = (_dec = (0, _object.computed)('style'), (_class = class RoseNotificationComponent extends _component2.default {
    /**
     * Returns an icon name based on the notification style.
     * @type {string}
     */
    get icon() {
      let icon = 'flight-icons/svg/info-16';

      switch (this.style) {
        case 'error':
          icon = 'flight-icons/svg/x-square-16';
          break;

        case 'success':
          icon = 'flight-icons/svg/check-circle-16';
          break;

        case 'warning':
          icon = 'flight-icons/svg/alert-triangle-16';
          break;
      }

      return icon;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "icon", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype)), _class));
  _exports.default = RoseNotificationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseNotificationComponent);
});