define("rose/components/rose/form/textarea/textarea/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Textarea
    ...attributes
    class="rose-form-input-field{{if @error " error"}}"
    id={{@id}}
    name={{@name}}
    disabled={{@disabled}}
    @value={{@value}}
    aria-describedby={{@aria-describedby}} />
  
  */
  {
    "id": "/671LYbg",
    "block": "[[[8,[39,0],[[17,1],[16,0,[29,[\"rose-form-input-field\",[52,[30,2],\" error\"]]]],[16,1,[30,3]],[16,3,[30,4]],[16,\"disabled\",[30,5]],[16,\"aria-describedby\",[30,6]]],[[\"@value\"],[[30,7]]],null],[1,\"\"]],[\"&attrs\",\"@error\",\"@id\",\"@name\",\"@disabled\",\"@aria-describedby\",\"@value\"],false,[\"textarea\",\"if\"]]",
    "moduleName": "rose/components/rose/form/textarea/textarea/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});