define("ember-breadcrumbs/components/bread-crumbs", ["exports", "@ember/utils", "@ember/array", "@ember/object", "@ember/component"], function (_exports, _utils, _array, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: null,
    routerService: null,
    routeInfos: (0, _object.computed)("routerService.currentRouteName", function () {
      var router = this.get("router")._routerMicrolib || this.get("router").router;
      return router.currentRouteInfos || router.currentHandlerInfos;
    }),

    /*
      For the pathNames and controllers properties, we must be careful not to NOT
      specify the properties of the route in our dependent keys.
       Observing the controller property of the route causes some serious problems:
      https://github.com/chrisfarber/ember-breadcrumbs/issues/21
    */
    pathNames: (0, _object.computed)("routeInfos.[]", function () {
      return this.get("routeInfos").map(function (routeInfo) {
        return routeInfo.name;
      });
    }),
    controllers: (0, _object.computed)("routeInfos.[]", function () {
      return this.get("routeInfos").map(function (routeInfo) {
        var route = routeInfo.route || routeInfo.handler;
        return route.controller;
      });
    }),
    breadCrumbs: (0, _object.computed)("controllers.@each.{breadCrumbs,breadCrumb,breadCrumbPath,breadCrumbModel}", "pathNames.[]", function () {
      var controllers = this.get("controllers");
      var defaultPaths = this.get("pathNames");
      var breadCrumbs = (0, _array.A)([]);
      controllers.forEach(function (controller, index) {
        var crumbs = controller.get("breadCrumbs") || (0, _array.A)([]);
        var singleCrumb = controller.get("breadCrumb");

        if (!(0, _utils.isBlank)(singleCrumb)) {
          crumbs.push({
            label: singleCrumb,
            path: controller.get("breadCrumbPath"),
            model: controller.get("breadCrumbModel")
          });
        }

        crumbs.forEach(function (crumb) {
          breadCrumbs.addObject(_object.default.create({
            label: crumb.label,
            path: crumb.path || defaultPaths[index],
            model: crumb.model,
            linkable: (0, _utils.isPresent)(crumb.linkable) ? crumb.linkable : crumb.path !== false,
            isCurrent: false
          }));
        });
      });
      var deepestCrumb = (0, _object.get)(breadCrumbs, "lastObject");

      if (deepestCrumb) {
        deepestCrumb.isCurrent = true;
      }

      return breadCrumbs;
    })
  });

  _exports.default = _default;
});