define("ember-radio-button/templates/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mWNUOeg4",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[10,\"label\"],[15,0,[29,[\"ember-radio-button \",[52,[33,2],[33,3]],\" \",[36,4]]]],[15,\"for\",[36,5]],[12],[1,\"\\n    \"],[1,[28,[35,6],null,[[\"class\",\"id\",\"autofocus\",\"disabled\",\"name\",\"required\",\"tabindex\",\"groupValue\",\"value\",\"ariaLabelledby\",\"ariaDescribedby\",\"changed\"],[[33,7],[33,5],[33,8],[33,9],[33,10],[33,11],[33,12],[33,13],[33,14],[33,15],[33,16],[28,[37,17],[[30,0],\"changed\"],null]]]]],[1,\"\\n\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,6],null,[[\"class\",\"id\",\"autofocus\",\"disabled\",\"name\",\"required\",\"tabindex\",\"groupValue\",\"value\",\"ariaLabelledby\",\"ariaDescribedby\",\"changed\"],[[33,7],[33,5],[33,8],[33,9],[33,10],[33,11],[33,12],[33,13],[33,14],[33,15],[33,16],[28,[37,17],[[30,0],\"changed\"],null]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"checked\",\"checkedClass\",\"joinedClassNames\",\"radioId\",\"radio-button-input\",\"radioClass\",\"autofocus\",\"disabled\",\"name\",\"required\",\"tabindex\",\"groupValue\",\"value\",\"ariaLabelledby\",\"ariaDescribedby\",\"action\",\"yield\"]]",
    "moduleName": "ember-radio-button/templates/components/radio-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});