define("rose/components/rose/footer/product/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="rose-footer-product">
    <span class="rose-footer-product-text">
      {{@text}}
    </span>
  
    {{#if @version}}
      <span class="rose-footer-product-version">
        v{{@version}}
      </span>
    {{/if}}
  </span>
  */
  {
    "id": "3FLk7A1I",
    "block": "[[[10,1],[14,0,\"rose-footer-product\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"rose-footer-product-text\"],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"    \"],[10,1],[14,0,\"rose-footer-product-version\"],[12],[1,\"\\n      v\"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@text\",\"@version\"],false,[\"if\"]]",
    "moduleName": "rose/components/rose/footer/product/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});