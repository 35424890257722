define("rose/components/rose/header/nav/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav class="rose-header-nav" aria-label="header-nav">
    {{yield (hash
      link=(component 'link-to' class="rose-header-nav-link")
      dropdown=(component 'rose/dropdown')
    )}}
  </nav>
  
  */
  {
    "id": "ev5wWH3T",
    "block": "[[[10,\"nav\"],[14,0,\"rose-header-nav\"],[14,\"aria-label\",\"header-nav\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"link\",\"dropdown\"],[[50,\"link-to\",0,null,[[\"class\"],[\"rose-header-nav-link\"]]],[50,\"rose/dropdown\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/header/nav/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});