define("api/models/fragment-account-attributes", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let FragmentAccountAttributesModel = (_dec = (0, _model.attr)('string', {
    for: 'password',
    description: 'The account login name'
  }), _dec2 = (0, _model.attr)('string', {
    for: 'oidc'
  }), _dec3 = (0, _model.attr)('string', {
    for: 'oidc'
  }), _dec4 = (0, _model.attr)('string', {
    for: 'oidc',
    readOnly: true
  }), _dec5 = (0, _model.attr)('string', {
    for: 'oidc',
    readOnly: true
  }), (_class = class FragmentAccountAttributesModel extends _fragment.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "login_name", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "subject", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "issuer", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "email", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "full_name", _descriptor5, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "login_name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "subject", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "issuer", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "email", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "full_name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FragmentAccountAttributesModel;
});