define("rose/components/rose/form/checkbox/group/index", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _applyDecoratedDescriptor2, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @items as |item|}}
    {{yield (hash
      item=item
      checkbox=(component 'rose/form/checkbox'
        name=@name
        disabled=@disabled
        inline=@inline
        checked=(includes item @selectedItems)
        onChange=(fn this.toggleItem item)
      )
    )}}
  {{/each}}
  
  */
  {
    "id": "Dhr9pCUO",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[18,7,[[28,[37,3],null,[[\"item\",\"checkbox\"],[[30,2],[50,\"rose/form/checkbox\",0,null,[[\"name\",\"disabled\",\"inline\",\"checked\",\"onChange\"],[[30,3],[30,4],[30,5],[28,[37,5],[[30,2],[30,6]],null],[28,[37,6],[[30,0,[\"toggleItem\"]],[30,2]],null]]]]]]]]],[1,\"\\n\"]],[2]],null]],[\"@items\",\"item\",\"@name\",\"@disabled\",\"@inline\",\"@selectedItems\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"hash\",\"component\",\"includes\",\"fn\"]]",
    "moduleName": "rose/components/rose/form/checkbox/group/index.hbs",
    "isStrictMode": false
  });

  /**
   * A checkbox group represents an array of arbitrary items, which differs from
   * single checkboxes which represent boolean values.  When checkboxes within a
   * checkbox are toggled, their associated item is toggled within the group's
   * array value.
   *
   * The term "item" is used to disambiguate it from "value", which has an
   * existing meaning in the context of form fields.  However, items
   * are just arbitrary values.
   */
  let RoseFormCheckboxGroupComponent = (_class = class RoseFormCheckboxGroupComponent extends _component2.default {
    // =actions

    /**
     * Returns a new copy of the @selectedItems array where the given item is:
     *   - removed from the array, if it was already present
     *   - or added to the array, if it was not already present
     */
    toggleItem(item) {
      const selectedItems = this.args.selectedItems || [];
      const currentItems = [...selectedItems];

      if (currentItems.includes(item)) {
        const i = currentItems.indexOf(item);
        currentItems.splice(i, 1);
      } else {
        currentItems.push(item);
      }

      if (this.args.onChange) this.args.onChange(currentItems);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleItem"), _class.prototype)), _class);
  _exports.default = RoseFormCheckboxGroupComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseFormCheckboxGroupComponent);
});