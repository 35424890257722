define("rose/components/rose/table/section/index", ["exports", "@ember/component", "@ember/template-factory", "rose/components/rose/table/section/index"], function (_exports, _component, _templateFactory, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    row=(component 'rose/table/row')
  )}}
  
  */
  {
    "id": "zmuAQbcd",
    "block": "[[[18,1,[[28,[37,1],null,[[\"row\"],[[50,\"rose/table/row\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/table/section/index.hbs",
    "isStrictMode": false
  });

  /**
   * A table section component that configures a row component.
   * Section can be: thead, tbody, or tfoot table elements.
   */
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    layout: _index.default,
    tagName: ''
  }));

  _exports.default = _default;
});