define("rose/components/rose/card/link/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <article ...attributes class="rose-card-link">
    <LinkTo
      title={{@title}}
      @route={{if @route @route "index"}}
      @model={{@model}}
    >
      <div class="rose-card-link-header">
        {{#if @title}}
          <h2 class="rose-card-link-title">
            {{#if @icon}}
              <Rose::Icon
                @name={{@icon}}
                @size="medium" />
            {{/if}}
            <span class="rose-card-link-title-text">{{@title}}</span>
          </h2>
        {{/if}}
      </div>
  
      <div class="rose-card-link-body">
        {{#if @description}}
          <p class="rose-card-link-description">{{@description}}</p>
        {{/if}}
      </div>
  
      <div class="rose-card-link-footer">
        {{#if @id}}
          <p class="rose-card-link-footer-id">{{@id}}</p>
        {{/if}}
      </div>
    </LinkTo>
  
    {{yield}}
  </article>
  
  */
  {
    "id": "Tkf3aRs+",
    "block": "[[[11,\"article\"],[17,1],[24,0,\"rose-card-link\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,\"title\",[30,2]]],[[\"@route\",\"@model\"],[[52,[30,3],[30,3],\"index\"],[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"rose-card-link-header\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,\"h2\"],[14,0,\"rose-card-link-title\"],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"            \"],[8,[39,2],null,[[\"@name\",\"@size\"],[[30,5],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[10,1],[14,0,\"rose-card-link-title-text\"],[12],[1,[30,2]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"rose-card-link-body\"],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,2],[14,0,\"rose-card-link-description\"],[12],[1,[30,6]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"rose-card-link-footer\"],[12],[1,\"\\n\"],[41,[30,7],[[[1,\"        \"],[10,2],[14,0,\"rose-card-link-footer-id\"],[12],[1,[30,7]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[18,8,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@title\",\"@route\",\"@model\",\"@icon\",\"@description\",\"@id\",\"&default\"],false,[\"link-to\",\"if\",\"rose/icon\",\"yield\"]]",
    "moduleName": "rose/components/rose/card/link/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});