define("api/serializers/managed-group", ["exports", "@babel/runtime/helpers/esm/defineProperty", "api/serializers/application"], function (_exports, _defineProperty2, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManagedGroupSerializer extends _application.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "serializeScopeID", false);
    }

  }

  _exports.default = ManagedGroupSerializer;
});