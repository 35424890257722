define("rose/components/rose/form/radio/group/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    radio=(component 'rose/form/radio/radio' name=@name selectedValue=@selectedValue disabled=@disabled changed=@changed inline=@inline)
  )}}
  */
  {
    "id": "9Ib9+FwT",
    "block": "[[[18,6,[[28,[37,1],null,[[\"radio\"],[[50,\"rose/form/radio/radio\",0,null,[[\"name\",\"selectedValue\",\"disabled\",\"changed\",\"inline\"],[[30,1],[30,2],[30,3],[30,4],[30,5]]]]]]]]]],[\"@name\",\"@selectedValue\",\"@disabled\",\"@changed\",\"@inline\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/form/radio/group/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});