define("api/models/role", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/role", "ember-data-model-fragments/attributes"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _role, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let RoleModel = (_dec = (0, _attributes.fragmentArray)('fragment-principal', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec2 = (0, _attributes.array)('string', {
    readOnly: true
  }), (_class = class RoleModel extends _role.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "principals", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "grant_strings", _descriptor2, this);
    }

    // =methods

    /**
     * Saves grant strings on the role via the `set-grants` method.
     * See serializer and adapter for more information.
     * @param {[string]} grantStrings
     * @return {Promise}
     */
    saveGrantStrings(grantStrings) {
      return this.save({
        adapterOptions: {
          method: 'set-grants',
          grantStrings
        }
      });
    }
    /**
     * Adds principals via the `add-principals` method.
     * See serializer and adapter for more information.
     * @param {[string]} principalIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addPrincipals(principalIDs, options = {
      adapterOptions: {}
    }) {
      const defaultAdapterOptions = {
        method: 'add-principals',
        principalIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete principals via the `remove-principals` method.
     * See serializer and adapter for more information.
     * @param {[string]} principalIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removePrincipals(principalIDs, options = {
      adapterOptions: {}
    }) {
      const defaultAdapterOptions = {
        method: 'remove-principals',
        principalIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single principal via the `remove-principals` method.
     * @param {number} principalIDs
     * @param {object} options
     * @return {Promise}
     */


    removePrincipal(principalID, options) {
      return this.removePrincipals([principalID], options);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "principals", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "grant_strings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RoleModel;
});