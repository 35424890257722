define("api/abilities/collection", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Generic ability provides abilities common to all models in the API.
   */
  class CollectionAbility extends _emberCan.Ability {
    // =permissions

    /**
     * @type {boolean}
     */
    get canList() {
      return this.hasAuthorizedCollectionAction('list');
    }
    /**
     * @type {boolean}
     */


    get canCreate() {
      return this.hasAuthorizedCollectionAction('create');
    } // =methods

    /**
     * Returns true if the given action is contained in the ability model's
     * `authorized_collection_actions` array.
     * @param {string} action
     * @return {boolean}
     */


    hasAuthorizedCollectionAction(action) {
      var _authorized_collectio;

      const authorized_collection_actions = (this.model || {}).authorized_collection_actions || {};
      const collection = this.collection || {};
      return (_authorized_collectio = authorized_collection_actions[collection]) === null || _authorized_collectio === void 0 ? void 0 : _authorized_collectio.includes(action);
    }

  }

  _exports.default = CollectionAbility;
});