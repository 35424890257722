define("rose/components/rose/form/checkbox/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/component", "@ember/template-factory", "@glimmer/component", "rose/utilities/component-auto-id"], function (_exports, _defineProperty2, _component, _templateFactory, _component2, _componentAutoId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="rose-form-checkbox{{if @error " error"}} {{if @inline " inline"}}">
  
    <Input
      ...attributes
      class="rose-form-checkbox-field"
      id={{this.id}}
      name={{@name}}
      disabled={{@disabled}}
      onChange={{@onChange}}
      @type="checkbox"
      @checked={{if @checked true false}}
      aria-describedby="{{if @helperText (concat 'helper-text-' this.id)}} {{if @error (concat 'errors-' this.id)}}" />
  
    <label class="rose-form-checkbox-label" for={{this.id}}>
      <span class="rose-form-checkbox-label-text">{{@label}}</span>
      {{#if @description}}
        <p class="rose-form-checkbox-label-description">{{@description}}</p>
      {{/if}}
    </label>
  
    {{#if @helperText}}
      <Rose::Form::HelperText @id="helper-text-{{this.id}}" @error={{@error}}>
        {{@helperText}}
      </Rose::Form::HelperText>
    {{/if}}
  
    {{yield (hash
      errors=(component 'rose/form/errors' id=(concat 'errors-' this.id))
    )}}
  
  </div>
  
  */
  {
    "id": "IL1/Kz0R",
    "block": "[[[10,0],[15,0,[29,[\"rose-form-checkbox\",[52,[30,1],\" error\"],\" \",[52,[30,2],\" inline\"]]]],[12],[1,\"\\n\\n  \"],[8,[39,1],[[17,3],[24,0,\"rose-form-checkbox-field\"],[16,1,[30,0,[\"id\"]]],[16,3,[30,4]],[16,\"disabled\",[30,5]],[16,\"onChange\",[30,6]],[16,\"aria-describedby\",[29,[[52,[30,7],[28,[37,2],[\"helper-text-\",[30,0,[\"id\"]]],null]],\" \",[52,[30,1],[28,[37,2],[\"errors-\",[30,0,[\"id\"]]],null]]]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[52,[30,8],true,false]]],null],[1,\"\\n\\n  \"],[10,\"label\"],[14,0,\"rose-form-checkbox-label\"],[15,\"for\",[30,0,[\"id\"]]],[12],[1,\"\\n    \"],[10,1],[14,0,\"rose-form-checkbox-label-text\"],[12],[1,[30,9]],[13],[1,\"\\n\"],[41,[30,10],[[[1,\"      \"],[10,2],[14,0,\"rose-form-checkbox-label-description\"],[12],[1,[30,10]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[30,7],[[[1,\"    \"],[8,[39,3],null,[[\"@id\",\"@error\"],[[29,[\"helper-text-\",[30,0,[\"id\"]]]],[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,7]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,11,[[28,[37,5],null,[[\"errors\"],[[50,\"rose/form/errors\",0,null,[[\"id\"],[[28,[37,2],[\"errors-\",[30,0,[\"id\"]]],null]]]]]]]]],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"@error\",\"@inline\",\"&attrs\",\"@name\",\"@disabled\",\"@onChange\",\"@helperText\",\"@checked\",\"@label\",\"@description\",\"&default\"],false,[\"if\",\"input\",\"concat\",\"rose/form/helper-text\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/form/checkbox/index.hbs",
    "isStrictMode": false
  });

  class RoseFormCheckboxComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "id", (0, _componentAutoId.generateComponentID)());
    }

  }

  _exports.default = RoseFormCheckboxComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseFormCheckboxComponent);
});