define("rose/components/rose/link-button/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    class="rose-link-button {{if @style (concat "rose-button-" @style)}} {{if @iconOnly 'has-icon-only'}} {{if @iconLeft 'has-icon-left'}} {{if @iconRight 'has-icon-right'}}"
    @route={{@route}}
    ...attributes>
    <span class="rose-button-wrapper">
      {{#if @iconLeft}}
        <Rose::Icon
          @name={{@iconLeft}}
          @size="medium"
        />
      {{/if}}
  
      <span class="rose-button-body">
        {{yield}}
      </span>
  
      {{#if @iconOnly}}
        <Rose::Icon
          @name={{@iconOnly}}
          @size="medium"
        />
      {{/if}}
  
      {{#if @iconRight}}
        <Rose::Icon
          @name={{@iconRight}}
          @size="medium"
        />
      {{/if}}
    </span>
  </LinkTo>
  
  */
  {
    "id": "SelhyGkt",
    "block": "[[[8,[39,0],[[16,0,[29,[\"rose-link-button \",[52,[30,1],[28,[37,2],[\"rose-button-\",[30,1]],null]],\" \",[52,[30,2],\"has-icon-only\"],\" \",[52,[30,3],\"has-icon-left\"],\" \",[52,[30,4],\"has-icon-right\"]]]],[17,5]],[[\"@route\"],[[30,6]]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[14,0,\"rose-button-wrapper\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[8,[39,3],null,[[\"@name\",\"@size\"],[[30,3],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,1],[14,0,\"rose-button-body\"],[12],[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"      \"],[8,[39,3],null,[[\"@name\",\"@size\"],[[30,2],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[8,[39,3],null,[[\"@name\",\"@size\"],[[30,4],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@style\",\"@iconOnly\",\"@iconLeft\",\"@iconRight\",\"&attrs\",\"@route\",\"&default\"],false,[\"link-to\",\"if\",\"concat\",\"rose/icon\",\"yield\"]]",
    "moduleName": "rose/components/rose/link-button/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});