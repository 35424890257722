define("api/models/fragment-host-attributes", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let FragmentHostAttributesModel = (_dec = (0, _model.attr)('string', {
    description: 'The address (DNS or IP name) used to reach the host'
  }), (_class = class FragmentHostAttributesModel extends _fragment.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "address", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "address", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FragmentHostAttributesModel;
});