define("api/models/fragment-auth-method-attributes", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data-model-fragments/fragment", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fragment, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  let FragmentAuthMethodAttributesModel = (_dec = (0, _model.attr)('string', {
    readOnly: true
  }), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _attributes.fragmentArray)('fragment-auth-method-attributes-account-claim-map', {
    emptyArrayIfMissing: true
  }), _dec12 = (0, _attributes.fragmentArray)('fragment-string', {
    emptyArrayIfMissing: true
  }), _dec13 = (0, _attributes.fragmentArray)('fragment-string', {
    emptyArrayIfMissing: true
  }), _dec14 = (0, _attributes.fragmentArray)('fragment-string', {
    emptyArrayIfMissing: true
  }), _dec15 = (0, _attributes.fragmentArray)('fragment-string', {
    emptyArrayIfMissing: true
  }), (_class = class FragmentAuthMethodAttributesModel extends _fragment.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "state", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "issuer", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "client_id", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "client_secret", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "client_secret_hmac", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "max_age", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "api_url_prefix", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "callback_url", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "disable_discovered_config_validation", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "dry_run", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "account_claim_maps", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "claims_scopes", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "signing_algorithms", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "allowed_audiences", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "idp_ca_certs", _descriptor15, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "issuer", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_id", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_secret", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_secret_hmac", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "max_age", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "api_url_prefix", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "callback_url", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disable_discovered_config_validation", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dry_run", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account_claim_maps", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "claims_scopes", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "signing_algorithms", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "allowed_audiences", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "idp_ca_certs", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FragmentAuthMethodAttributesModel;
});