define("api/serializers/group", ["exports", "api/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GroupSerializer extends _application.default {
    // =methods

    /**
     * If `adapterOptions.memberIDs` is set (to an array of user IDs),
     * then the payload is serialized via `serializewithMembers`.
     * @override
     * @param {Snapshot} snapshot
     * @return {object}
     */
    serialize(snapshot) {
      var _snapshot$adapterOpti;

      let serialized = super.serialize(...arguments);
      const memberIDs = snapshot === null || snapshot === void 0 ? void 0 : (_snapshot$adapterOpti = snapshot.adapterOptions) === null || _snapshot$adapterOpti === void 0 ? void 0 : _snapshot$adapterOpti.memberIDs;
      if (memberIDs) serialized = this.serializewithMembers(snapshot, memberIDs);
      return serialized;
    }
    /**
     * Returns a payload containing only the `member_ids` array (and version).
     * @param {Snapshot} snapshot
     * @param {[string]} memberIDs
     * @return {object}
     */


    serializewithMembers(snapshot, memberIDs) {
      return {
        version: snapshot.attr('version'),
        member_ids: memberIDs
      };
    }

  }

  _exports.default = GroupSerializer;
});