define("api/serializers/host-set", ["exports", "@babel/runtime/helpers/esm/defineProperty", "api/serializers/application"], function (_exports, _defineProperty2, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HostSetSerializer extends _application.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "serializeScopeID", false);
    }

    // =methods

    /**
     * If `adapterOptions.serializeHostIDs` is true, the serialization should
     * include **only host_ids** and the version.  Normally, these are not
     * serialized.
     * @override
     * @param {Snapshot} snapshot
     * @return {object}
     */
    serialize(snapshot) {
      var _snapshot$adapterOpti;

      let serialized = super.serialize(...arguments);
      const hostIDs = snapshot === null || snapshot === void 0 ? void 0 : (_snapshot$adapterOpti = snapshot.adapterOptions) === null || _snapshot$adapterOpti === void 0 ? void 0 : _snapshot$adapterOpti.hostIDs;
      if (hostIDs) serialized = this.serializeWithHostIDs(snapshot, hostIDs);
      return serialized;
    }
    /**
     * Returns a payload containing only the host_ids array.
     * @param {Snapshot} snapshot
     * @param {[string]} hostIDs
     * @return {object}
     */


    serializeWithHostIDs(snapshot, hostIDs) {
      return {
        version: snapshot.attr('version'),
        host_ids: hostIDs
      };
    }

  }

  _exports.default = HostSetSerializer;
});