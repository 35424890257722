define("rose/components/rose/table/row/index", ["exports", "@ember/component", "@ember/template-factory", "rose/components/rose/table/row/index"], function (_exports, _component, _templateFactory, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr ...attributes class='rose-table-row {{if @hidden (concat "rose-table-row-visually-hidden")}} {{if @style (concat "rose-table-row-" @style)}}'>
    {{yield (hash
      cell=(component 'rose/table/row/cell')
      headerCell=(component 'rose/table/row/cell' tagName='th')
    )}}
  </tr>
  
  */
  {
    "id": "+74Tq3B3",
    "block": "[[[11,\"tr\"],[17,1],[16,0,[29,[\"rose-table-row \",[52,[30,2],[28,[37,1],[\"rose-table-row-visually-hidden\"],null]],\" \",[52,[30,3],[28,[37,1],[\"rose-table-row-\",[30,3]],null]]]]],[12],[1,\"\\n  \"],[18,4,[[28,[37,3],null,[[\"cell\",\"headerCell\"],[[50,\"rose/table/row/cell\",0,null,null],[50,\"rose/table/row/cell\",0,null,[[\"tagName\"],[\"th\"]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@hidden\",\"@style\",\"&default\"],false,[\"if\",\"concat\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/table/row/index.hbs",
    "isStrictMode": false
  });

  /**
   * A table row component that uses tr table element and
   * configures a table cell element.
   */
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    layout: _index.default,
    tagName: ''
  }));

  _exports.default = _default;
});