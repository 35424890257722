define("rose/components/rose/layout/sidebar/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class="rose-layout-sidebar">
    {{yield (hash
      body=(component "rose/anonymous" class="rose-layout-sidebar-body")
      sidebar=(component "rose/anonymous" tagName="aside" class="rose-layout-sidebar-sidebar")
    )}}
  </div>
  
  */
  {
    "id": "Yv1YXg0/",
    "block": "[[[11,0],[17,1],[24,0,\"rose-layout-sidebar\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"body\",\"sidebar\"],[[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-layout-sidebar-body\"]]],[50,\"rose/anonymous\",0,null,[[\"tagName\",\"class\"],[\"aside\",\"rose-layout-sidebar-sidebar\"]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/layout/sidebar/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});