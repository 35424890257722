define("api/abilities/model", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Generic ability provides abilities common to all models in the API.
   */
  class ModelAbility extends _emberCan.Ability {
    // =permissions

    /**
     * @type {boolean}
     */
    get canRead() {
      return this.hasAuthorizedAction('read');
    }
    /**
     * @type {boolean}
     */


    get canUpdate() {
      return this.hasAuthorizedAction('update');
    }
    /**
     * @type {boolean}
     */


    get canDelete() {
      return this.hasAuthorizedAction('delete');
    }
    /**
     * To save an existing model (already persisted to the API), it must contain
     * the the `update` authorized action.
     *
     * For now, new models _always_ have this ability.  The reason is because the
     * `create` action is authorized on collections, not individual records.  So
     * this function can't actually tell from a model instance if create
     * is allowed.
     *
     * @type {boolean}
     */


    get canSave() {
      if (this.model.isNew || !this.model.isNew && this.canUpdate) {
        return true;
      }

      return false;
    } // =methods

    /**
     * Returns true if the given action is contained in the ability model's
     * `authorized_actions` array.
     * @param {string} action
     * @return {boolean}
     */


    hasAuthorizedAction(action) {
      var _this$model$authorize;

      return (_this$model$authorize = this.model.authorized_actions) === null || _this$model$authorize === void 0 ? void 0 : _this$model$authorize.includes(action);
    }

  }

  _exports.default = ModelAbility;
});