define("rose/components/rose/form/actions/edit-toggle/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="rose-form-actions" ...attributes>
    <Rose::Button
      @style="secondary"
      {{on "click" @enableEdit}}
    >
      {{@enableEditText}}
    </Rose::Button>
  </div>
  
  */
  {
    "id": "1xfJFxgr",
    "block": "[[[11,0],[24,0,\"rose-form-actions\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],[[4,[38,1],[\"click\",[30,2]],null]],[[\"@style\"],[\"secondary\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@enableEdit\",\"@enableEditText\"],false,[\"rose/button\",\"on\"]]",
    "moduleName": "rose/components/rose/form/actions/edit-toggle/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});