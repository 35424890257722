define("ember-radio-button/components/radio-button-input", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/runloop"], function (_exports, _component, _object, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'input',
    type: 'radio',
    // value - required
    // groupValue - required
    // autofocus - boolean
    // disabled - optional
    // name - optional
    // required - optional
    // radioClass - string
    // radioId - string
    // tabindex - number
    // ariaLabelledby - string
    // ariaDescribedby - string
    defaultLayout: null,
    // ie8 support
    attributeBindings: ['autofocus', 'checked', 'disabled', 'name', 'required', 'tabindex', 'type', 'value', 'ariaLabelledby:aria-labelledby', 'ariaDescribedby:aria-describedby', 'checkedStr:aria-checked'],
    checked: (0, _object.computed)('groupValue', 'value', function () {
      return (0, _utils.isEqual)(this.get('groupValue'), this.get('value'));
    }).readOnly(),
    checkedStr: (0, _object.computed)('checked', function () {
      let checked = this.get('checked');

      if (typeof checked === 'boolean') {
        return checked.toString();
      }

      return null;
    }),

    invokeChangedAction() {
      let value = this.get('value');
      let changedAction = this.get('changed');

      if (typeof changedAction === 'string') {
        this.sendAction('changed', value);
        return;
      }

      if (changedAction) {
        changedAction(value);
      }
    },

    change() {
      let value = this.get('value');
      let groupValue = this.get('groupValue');

      if (groupValue !== value) {
        this.set('groupValue', value);

        _runloop.run.once(this, 'invokeChangedAction');
      }
    }

  });

  _exports.default = _default;
});