define("core/authenticators/password", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "auth/authenticators/password", "@ember/service"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _password, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  /**
   * A username/password authenticator that authenticates with
   * a scope/auth-method endpoint.  Authentication occurs on an auth method
   * endpoint, while deauthentication occurs on a scope endpoint.
   */
  let PasswordAuthenticator = (_class = class PasswordAuthenticator extends _password.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
    }

    // =attributes

    /**
     * Generates an auth method URL with which to authenticate.
     * @override
     * @param {object} options
     * @param {string} options.scope.scope_id
     * @param {string} options.authMethod.id
     * @return {string}
     */
    buildAuthEndpointURL({
      scope: {
        id: scopeID
      },
      authMethod: {
        id: authMethodID
      }
    }) {
      const adapter = this.store.adapterFor('application');
      const options = {
        adapterOptions: {
          scopeID,
          method: 'authenticate'
        }
      };
      return adapter.buildURL('auth-method', authMethodID, options, 'findRecord');
    }
    /**
     * Generates a scope URL with which to deauthenticate.
     * @override
     * @param {object} options
     * @param {string} scopeID
     * @return {string}
     */


    buildDeauthEndpointURL({
      scope: {
        id: scopeID
      }
    }) {
      const adapter = this.store.adapterFor('application');
      const options = {
        adapterOptions: {
          method: 'deauthenticate'
        }
      };
      return adapter.buildURL('scope', scopeID, options, 'findRecord');
    }
    /**
     * Generates an auth token validation URL used to check tokens on restoration.
     * @override
     * @param {string} tokenID
     * @return {string}
     */


    buildTokenValidationEndpointURL(tokenID) {
      const adapter = this.store.adapterFor('auth-token');
      return adapter.buildURL('auth-token', tokenID, {}, 'findRecord');
    }
    /**
     * Intercepts the authenticate response, if any, and assigns the returned
     * token to all future requests via `addTokenToAuthorization`.
     * Returns the response data as normal.
     * @override
     */


    authenticate() {
      return super.authenticate(...arguments).then(data => {
        var _data$attributes;

        const token = data === null || data === void 0 ? void 0 : (_data$attributes = data.attributes) === null || _data$attributes === void 0 ? void 0 : _data$attributes.token;
        this.addTokenToAuthorization(token);
        return data;
      });
    }
    /**
     * When restoring the session (say, on load), assigns the session token
     * to all future requests via `addTokenToAuthorization`.
     * @override
     * @param {object} data
     * @return {object}
     */


    restore(data) {
      var _data$attributes2;

      const token = data === null || data === void 0 ? void 0 : (_data$attributes2 = data.attributes) === null || _data$attributes2 === void 0 ? void 0 : _data$attributes2.token;
      this.addTokenToAuthorization(token);
      return super.restore(data);
    }
    /**
     * Assigns a token string all future requests via the `Authorization` header
     * on the application adapter prototype.  Only application and adapters that
     * extend the application adapter receive this header (which is most).
     *
     * TODO:  if cookies were requested, this step should be skipped.
     *
     * @param {string} token
     */


    addTokenToAuthorization(token) {
      const adapterPrototype = this.store.adapterFor('application').constructor.prototype;
      const headers = adapterPrototype === null || adapterPrototype === void 0 ? void 0 : adapterPrototype.headers;
      if (!headers) adapterPrototype.headers = {};
      adapterPrototype.headers.Authorization = null;
      adapterPrototype.headers.Authorization = `Bearer ${token}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = PasswordAuthenticator;
});