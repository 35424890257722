define("rose/components/rose/dropdown/link/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!
    Ember's LinkTo component won't accept nullish @query or @model, so we have to
    overcompensate by duplicating it four times, one for every combination.
    This is really unpleasant.
  }}
  {{#if @model}}
    {{#if @query}}
      <LinkTo
        ...attributes
        @route={{@route}}
        @query={{@query}}
        @model={{@model}}
        class="rose-dropdown-link {{if  @style (concat "rose-dropdown-link-" @style)}}"
      >
        {{yield}}
      </LinkTo>
    {{else}}
      <LinkTo
        ...attributes
        @route={{@route}}
        @model={{@model}}
        class="rose-dropdown-link {{if  @style (concat "rose-dropdown-link-" @style)}}"
      >
        {{yield}}
      </LinkTo>
    {{/if}}
  {{else}}
    {{#if @query}}
      <LinkTo
        ...attributes
        @route={{@route}}
        @query={{@query}}
        class="rose-dropdown-link {{if  @style (concat "rose-dropdown-link-" @style)}}"
      >
        {{yield}}
      </LinkTo>
    {{else}}
      <LinkTo
        ...attributes
        @route={{@route}}
        class="rose-dropdown-link {{if  @style (concat "rose-dropdown-link-" @style)}}"
      >
        {{yield}}
      </LinkTo>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "wIK2nfts",
    "block": "[[[41,[30,1],[[[41,[30,2],[[[1,\"    \"],[8,[39,1],[[17,3],[16,0,[29,[\"rose-dropdown-link \",[52,[30,4],[28,[37,2],[\"rose-dropdown-link-\",[30,4]],null]]]]]],[[\"@route\",\"@query\",\"@model\"],[[30,5],[30,2],[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],[[17,3],[16,0,[29,[\"rose-dropdown-link \",[52,[30,4],[28,[37,2],[\"rose-dropdown-link-\",[30,4]],null]]]]]],[[\"@route\",\"@model\"],[[30,5],[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,2],[[[1,\"    \"],[8,[39,1],[[17,3],[16,0,[29,[\"rose-dropdown-link \",[52,[30,4],[28,[37,2],[\"rose-dropdown-link-\",[30,4]],null]]]]]],[[\"@route\",\"@query\"],[[30,5],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],[[17,3],[16,0,[29,[\"rose-dropdown-link \",[52,[30,4],[28,[37,2],[\"rose-dropdown-link-\",[30,4]],null]]]]]],[[\"@route\"],[[30,5]]],[[\"default\"],[[[[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]]],[\"@model\",\"@query\",\"&attrs\",\"@style\",\"@route\",\"&default\"],false,[\"if\",\"link-to\",\"concat\",\"yield\"]]",
    "moduleName": "rose/components/rose/dropdown/link/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});