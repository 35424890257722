define("ember-modifier/-private/functional/modifier-manager", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/modifier", "ember-modifier/-private/compat"], function (_exports, _defineProperty2, _modifier, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MODIFIER_ELEMENTS = new WeakMap();
  const MODIFIER_TEARDOWNS = new WeakMap();

  function teardown(modifier) {
    const teardown = MODIFIER_TEARDOWNS.get(modifier);

    if (teardown && typeof teardown === 'function') {
      teardown();
    }
  }

  function setup(modifier, element, args) {
    const {
      positional,
      named
    } = args;
    const teardown = modifier(element, positional, named);
    MODIFIER_TEARDOWNS.set(modifier, teardown);
  }

  class FunctionalModifierManager {
    constructor() {
      (0, _defineProperty2.default)(this, "capabilities", (0, _modifier.capabilities)(true ? '3.22' : '3.13'));
    }

    createModifier(factoryOrClass) {
      const Modifier = (0, _compat.isFactory)(factoryOrClass) ? factoryOrClass.class : factoryOrClass; // This looks superfluous, but this is creating a new instance
      // of a function -- this is important so that each instance of the
      // created modifier can have its own state which is stored in
      // the MODIFIER_ELEMENTS and MODIFIER_TEARDOWNS WeakMaps

      return (...args) => Modifier(...args);
    }

    installModifier(modifier, element, args) {
      MODIFIER_ELEMENTS.set(modifier, element);

      if (true) {
        (0, _compat.consumeArgs)(args);
      }

      setup(modifier, element, args);
    }

    updateModifier(modifier, args) {
      const element = MODIFIER_ELEMENTS.get(modifier);
      teardown(modifier);

      if (true) {
        (0, _compat.consumeArgs)(args);
      }

      setup(modifier, element, args);
    }

    destroyModifier(modifier) {
      teardown(modifier);
    }

  }

  var _default = new FunctionalModifierManager();

  _exports.default = _default;
});