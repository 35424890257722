define("core/helpers/has-resource-filter-selections", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component/helper", "@ember/service", "@ember/application", "@ember/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _helper, _service, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  /**
   * Use this helper to determine if a resource filter for a route has any
   * currently selected value.
   *
   * @see resourceFilterParam (decorator)
   *
   * @example
   *
   *   {{#if (has-resource-filter-selections 'route.name' 'field')}}
   *     Field has filter selections.
   *   {{/if}}
   */
  let HasRouteResourceFilterSelectionsHelper = (_class = class HasRouteResourceFilterSelectionsHelper extends _helper.default {
    // =services
    // =methods
    // =lifecycle management methods

    /**
     * Recompute this helper when the route changes.
     */
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      this.router.on('routeDidChange', this.routeDidChange);
    }
    /**
     * Stop listening to route events when this helper is destroyed.
     */


    willDestroy() {
      this.router.off('routeDidChange', this.routeDidChange);
      super.willDestroy();
    } // =compute method

    /**
     * Returns true if the specified route has a value set for
     * any resource filter passed into the helper.
     * @param {string} routeName
     * @param {string[]} names - names of resource filter fields
     * @return {boolean}
     */


    compute([routeName, ...names]) {
      const owner = (0, _application.getOwner)(this);
      const route = owner.lookup(`route:${routeName}`);
      const selectedValues = names.map(name => route[name] || null).flat();
      const anyTruthy = selectedValues.reduce((previousValue, currentValue) => previousValue || currentValue, false);
      return Boolean(anyTruthy);
    } // =actions

    /**
     * Triggers a recompute (see above) when the route changes, especially when
     * route query params change.
     */


    routeDidChange() {
      this.recompute();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "routeDidChange"), _class.prototype)), _class);
  _exports.default = HasRouteResourceFilterSelectionsHelper;
});