define("ember-loading/services/loading", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/service", "@ember/object", "@ember/object/computed", "ember-concurrency", "@ember/application", "rsvp"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _service, _object, _computed, _emberConcurrency, _application, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function parseArgs() {
    let length = arguments.length;
    let args;
    let method;
    let target;

    if (length === 1) {
      target = null;
      method = arguments[0];
    } else if (length > 1) {
      let argsIndex = 2;
      let methodOrTarget = arguments[0];
      let methodOrArgs = arguments[1];
      let type = typeof methodOrArgs;

      if (type === 'function') {
        target = methodOrTarget;
        method = methodOrArgs;
      } else if (methodOrTarget !== null && type === 'string' && methodOrArgs in methodOrTarget) {
        target = methodOrTarget;
        method = target[methodOrArgs];
      } else if (typeof methodOrTarget === 'function') {
        argsIndex = 1;
        target = null;
        method = methodOrTarget;
      }

      if (length > argsIndex) {
        let len = length - argsIndex;
        args = new Array(len);

        for (let i = 0; i < len; i++) {
          args[i] = arguments[i + argsIndex];
        }
      }
    }

    return [target, method, args];
  }

  let LoadingService = (_dec = (0, _computed.readOnly)('_runJob.isRunning'), _dec2 = (0, _object.computed)('isLoading', 'preDelayTask.isRunning', 'postDelayTask.isRunning'), _dec3 = (0, _emberConcurrency.task)(function* () {
    let [target, method, args] = parseArgs(...arguments);
    return yield method.apply(target, args);
  }), _dec4 = (0, _emberConcurrency.task)(function* (delay) {
    yield (0, _emberConcurrency.timeout)(delay);
  }).restartable(), _dec5 = (0, _emberConcurrency.task)(function* (delay) {
    yield (0, _emberConcurrency.timeout)(delay);
  }).restartable(), (_class = class LoadingService extends _service.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      this.postDelay = 0;
      this.preDelay = 0;
      this.watchTransitions = true;
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor2, this);
      this._routerTransitionDeferred = void 0;
      (0, _initializerDefineProperty2.default)(this, "_runJob", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "preDelayTask", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "postDelayTask", _descriptor5, this);
    }

    get showLoading() {
      // @ts-ignore
      return !this.preDelayTask.isRunning && (this.isLoading || this.postDelayTask.isRunning);
    }

    _routeWillChange() {
      let deferred = (0, _rsvp.defer)();

      if (this._routerTransitionDeferred) {
        this._routerTransitionDeferred.resolve();
      }

      this.set('_routerTransitionDeferred', deferred);
      this.run(() => deferred.promise);
    }

    _routeDidChange() {
      if (this._routerTransitionDeferred) {
        this._routerTransitionDeferred.resolve();

        this.set('_routerTransitionDeferred', undefined);
      }
    }

    init() {
      super.init();
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment')['ember-loading'];

      if (config) {
        this.preDelay = config.preDelay || 0;
        this.postDelay = config.postDelay || 0;
        this.watchTransitions = config.watchTransitions === false ? false : true;
      }

      if (this.watchTransitions) {
        this.router.on('routeWillChange', this._routeWillChange);
        this.router.on('routeDidChange', this._routeDidChange);
      }
    }

    willDestroy() {
      super.willDestroy();

      if (this.watchTransitions) {
        this.router.off('routeWillChange', this._routeWillChange);
        this.router.off('routeDidChange', this._routeDidChange);
      }
    } // @todo Revisit this stronger typing when https://github.com/typed-ember/ember-cli-typescript/issues/590 is resolved
    //
    // run<T, P1, P2, P3, P4, P5, P6, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): Promise<R>;
    // run<T, P1, P2, P3, P4, P5, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4, p5: P5) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): Promise<R>;
    // run<T, P1, P2, P3, P4, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4): Promise<R>;
    // run<T, P1, P2, P3, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3) => R) | keyof T, p1: P1, p2: P2, p3: P3): Promise<R>;
    // run<T, P1, P2, R>(target: T, fn: ((p1: P1, p2: P2) => R) | keyof T, p1: P1, p2: P2): Promise<R>;
    // run<T, P1, R>(target: T, fn: ((p1: P1) => R) | keyof T, p1: P1): Promise<R>;
    // run<T, R>(target: T, fn: (() => R) | keyof T): Promise<R>
    // run<P1, P2, P3, P4, P5, P6, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6) => R, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): Promise<R>;
    // run<P1, P2, P3, P4, P5, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4, p5: P5) => R, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): Promise<R>;
    // run<P1, P2, P3, P4, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4) => R, p1: P1, p2: P2, p3: P3, p4: P4): Promise<R>;
    // run<P1, P2, P3, R>(fn: (p1: P1, p2: P2, p3: P3) => R, p1: P1, p2: P2, p3: P3): Promise<R>;
    // run<P1, P2, R>(fn: (p1: P1, p2: P2) => R, p1: P1, p2: P2): Promise<R>;
    // run<P1, R>(fn: (p1: P1) => R, p1: P1): Promise<R>;
    // run<R>(fn: () => R): Promise<R>;
    // run<T, R>(target: T, fn: ((...args: any[]) => R) | keyof T, ...args: any[]): Promise<R>;
    // run<T, R>(target: T, fn: (() => R) | keyof T): Promise<R>;
    // run<R>(fn: (...args: any[]) => R, ...args: any[]): Promise<R>;
    // run<R>(fn: () => R): Promise<R>;


    async run(...args) {
      if (this.preDelay > 0) {
        // @ts-ignore
        this.preDelayTask.perform(this.preDelay);
      }

      let result = await this._runJob.perform(...args);

      if (this.postDelay > 0) {
        // @ts-ignore
        this.postDelayTask.perform(this.postDelay);
      }

      return result;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showLoading", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showLoading"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_routeWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeWillChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeDidChange"), _class.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_runJob", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "preDelayTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "postDelayTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.

  _exports.default = LoadingService;
});