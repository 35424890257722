define("rose/components/rose/cards/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    class="rose-cards"
  >
    {{yield (hash
      link=(component "rose/card/link")
    )}}
  </div>
  
  */
  {
    "id": "LkzylfeI",
    "block": "[[[11,0],[17,1],[24,0,\"rose-cards\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"link\"],[[50,\"rose/card/link\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/cards/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});