define("rose/components/rose/form/radio/radio/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/component", "@ember/template-factory", "@glimmer/component", "rose/utilities/component-auto-id"], function (_exports, _defineProperty2, _component, _templateFactory, _component2, _componentAutoId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="rose-form-radio{{if @error " error"}} {{if @inline "inline"}}">
  
    <RadioButton
      ...attributes
      @radioClass="rose-form-radio-field"
      @radioId={{this.id}}
      type="radio"
      disabled={{@disabled}}
      @name={{@name}}
      @value={{@value}}
      @groupValue={{@selectedValue}}
      @disabled={{@disabled}}
      @changed={{@changed}} />
  
    <label class="rose-form-radio-label" for={{this.id}}>
  
      {{#if @icon}}
        <Rose::Icon
          @name={{@icon}}
          @size="medium"
        />
      {{/if}}
  
      {{@label}}
  
    </label>
  
    {{yield}}
  
  </div>
  
  */
  {
    "id": "T2hbpOF8",
    "block": "[[[10,0],[15,0,[29,[\"rose-form-radio\",[52,[30,1],\" error\"],\" \",[52,[30,2],\"inline\"]]]],[12],[1,\"\\n\\n  \"],[8,[39,1],[[17,3],[24,4,\"radio\"],[16,\"disabled\",[30,4]]],[[\"@radioClass\",\"@radioId\",\"@name\",\"@value\",\"@groupValue\",\"@disabled\",\"@changed\"],[\"rose-form-radio-field\",[30,0,[\"id\"]],[30,5],[30,6],[30,7],[30,4],[30,8]]],null],[1,\"\\n\\n  \"],[10,\"label\"],[14,0,\"rose-form-radio-label\"],[15,\"for\",[30,0,[\"id\"]]],[12],[1,\"\\n\\n\"],[41,[30,9],[[[1,\"      \"],[8,[39,2],null,[[\"@name\",\"@size\"],[[30,9],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[1,[30,10]],[1,\"\\n\\n  \"],[13],[1,\"\\n\\n  \"],[18,11,null],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"@error\",\"@inline\",\"&attrs\",\"@disabled\",\"@name\",\"@value\",\"@selectedValue\",\"@changed\",\"@icon\",\"@label\",\"&default\"],false,[\"if\",\"radio-button\",\"rose/icon\",\"yield\"]]",
    "moduleName": "rose/components/rose/form/radio/radio/index.hbs",
    "isStrictMode": false
  });

  class RoseFormRadioRadioComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "id", (0, _componentAutoId.generateComponentID)());
    }

  }

  _exports.default = RoseFormRadioRadioComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseFormRadioRadioComponent);
});