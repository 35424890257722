define("rose/components/rose/form/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form
    ...attributes
    novalidate
    method="post"
    class="rose-form"
    autocomplete="off"
    {{on "submit" this.handleSubmit}}
  >
    {{!--
      Only show the edit toggle button if the toggle is enabled in the first place
      AND the form is not already in editable mode.
  
      The cancel button's disabled state depends on the mode.  In edit mode,
      the cancel button is only disabled if the disabled argument is true,
      otherwise it is disabled based on the computed property this.disabled.
    --}}
    {{yield (hash
      radioGroup=(component 'rose/form/radio/group' disabled=this.disabled)
      checkbox=(component 'rose/form/checkbox' disabled=this.disabled)
      checkboxGroup=(component 'rose/form/checkbox/group' disabled=this.disabled)
      input=(component 'rose/form/input' disabled=this.disabled)
      select=(component 'rose/form/select' disabled=this.disabled)
      textarea=(component 'rose/form/textarea' disabled=this.disabled)
      fieldset=(component 'rose/form/fieldset')
      actions=(component
        (if this.showEditToggleButton
          'rose/form/actions/edit-toggle'
          'rose/form/actions'
        )
        submitDisabled=this.disabled
        cancelDisabled=(if @showEditToggle @disabled this.disabled)
        cancel=this.handleCancel
        enableEdit=this.enableEdit
      )
      isEditable=this.isEditable
    )}}
  </form>
  
  */
  {
    "id": "hHxfBzwz",
    "block": "[[[11,\"form\"],[17,1],[24,\"novalidate\",\"\"],[24,\"method\",\"post\"],[24,0,\"rose-form\"],[24,\"autocomplete\",\"off\"],[4,[38,0],[\"submit\",[30,0,[\"handleSubmit\"]]],null],[12],[1,\"\\n\"],[1,\"  \"],[18,4,[[28,[37,2],null,[[\"radioGroup\",\"checkbox\",\"checkboxGroup\",\"input\",\"select\",\"textarea\",\"fieldset\",\"actions\",\"isEditable\"],[[50,\"rose/form/radio/group\",0,null,[[\"disabled\"],[[30,0,[\"disabled\"]]]]],[50,\"rose/form/checkbox\",0,null,[[\"disabled\"],[[30,0,[\"disabled\"]]]]],[50,\"rose/form/checkbox/group\",0,null,[[\"disabled\"],[[30,0,[\"disabled\"]]]]],[50,\"rose/form/input\",0,null,[[\"disabled\"],[[30,0,[\"disabled\"]]]]],[50,\"rose/form/select\",0,null,[[\"disabled\"],[[30,0,[\"disabled\"]]]]],[50,\"rose/form/textarea\",0,null,[[\"disabled\"],[[30,0,[\"disabled\"]]]]],[50,\"rose/form/fieldset\",0,null,null],[50,[52,[30,0,[\"showEditToggleButton\"]],\"rose/form/actions/edit-toggle\",\"rose/form/actions\"],0,null,[[\"submitDisabled\",\"cancelDisabled\",\"cancel\",\"enableEdit\"],[[30,0,[\"disabled\"]],[52,[30,2],[30,3],[30,0,[\"disabled\"]]],[30,0,[\"handleCancel\"]],[30,0,[\"enableEdit\"]]]]],[30,0,[\"isEditable\"]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@showEditToggle\",\"@disabled\",\"&default\"],false,[\"on\",\"yield\",\"hash\",\"component\",\"if\"]]",
    "moduleName": "rose/components/rose/form/index.hbs",
    "isStrictMode": false
  });

  let RoseFormComponent = (_dec = (0, _object.computed)('args.disabled', 'args.showEditToggle', 'isEditable'), _dec2 = (0, _object.computed)('args.showEditToggle', 'isEditable'), (_class = class RoseFormComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "isEditable", _descriptor, this);
    }

    /**
     * By default, this field is unconditionally equal to the value of the passed
     * `@disabled` argument.  If desired the form may be "locked" from editing
     * until the user intentionally enables edit mode (`@showEditToggle` is true),
     * in which case the value is dependent on `@disabled` and `this.isEditable`.
     * @type {boolean}
     */
    get disabled() {
      if (this.args.showEditToggle) return this.args.disabled || !this.isEditable;
      return this.args.disabled;
    }
    /**
     * True if showEditToggle is true and edit mode is enabled.
     * @type {boolean}
     */


    get showEditToggleButton() {
      return this.args.showEditToggle && !this.isEditable;
    } // =actions

    /**
     * Calls the passed `onSubmit` function while disabling the default form
     * submit behavior.
     *
     * Submit handlers _may optionally return a promise_.  If they do, the promise
     * is assumed to reflect the state of the submission operation,
     * i.e. if the promise resolves the submission was successful and if it
     * rejects, the submission failed.
     * @param {Event} e
     */


    handleSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      const submitResult = this.args.onSubmit(); // If the submit handler returns a promise, we want to handle success
      // by re-enabling read-only mode on the form (if applicable).
      // Since submit is allowed to fail in this context and we don't want
      // unhandled rejection warnings, we have a no-op catch.

      if (submitResult !== null && submitResult !== void 0 && submitResult.then) {
        submitResult.then(() => this.isEditable = false).catch(() => {});
      }

      return false;
    }
    /**
     * Calls the passed `cancel` function.
     * @param {Event} e
     */


    handleCancel() {
      this.disableEdit();
      this.args.cancel();
    }
    /**
     * Enables edit mode, which only applies when `@showEditToggle` is true.
     */


    enableEdit() {
      this.isEditable = true;
    }
    /**
     * Disables edit mode, which only applies when `@showEditToggle` is true.
     */


    disableEdit() {
      this.isEditable = false;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isEditable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showEditToggleButton", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showEditToggleButton"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleCancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCancel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "enableEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "enableEdit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disableEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "disableEdit"), _class.prototype)), _class));
  _exports.default = RoseFormComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseFormComponent);
});