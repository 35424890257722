define("rose/components/rose/nav/breadcrumbs/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav ...attributes class="rose-nav-breadcrumbs" aria-label="breadcrumbs">
    {{yield (hash
      link=(component "link-to" class="rose-nav-breadcrumbs-link")
    )}}
  </nav>
  
  */
  {
    "id": "JQ0wNz9T",
    "block": "[[[11,\"nav\"],[17,1],[24,0,\"rose-nav-breadcrumbs\"],[24,\"aria-label\",\"breadcrumbs\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"link\"],[[50,\"link-to\",0,null,[[\"class\"],[\"rose-nav-breadcrumbs-link\"]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/nav/breadcrumbs/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});