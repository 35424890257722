define("rose/components/rose/form/input/input/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
    ...attributes
    class="rose-form-input-field{{if @error " error"}}"
    id={{@id}}
    disabled={{@disabled}}
    name={{@name}}
    @type={{@type}}
    @value={{@value}}
    aria-describedby={{@aria-describedby}} />
  
  */
  {
    "id": "NEdsoBhS",
    "block": "[[[8,[39,0],[[17,1],[16,0,[29,[\"rose-form-input-field\",[52,[30,2],\" error\"]]]],[16,1,[30,3]],[16,\"disabled\",[30,4]],[16,3,[30,5]],[16,\"aria-describedby\",[30,6]]],[[\"@type\",\"@value\"],[[30,7],[30,8]]],null],[1,\"\\n\"]],[\"&attrs\",\"@error\",\"@id\",\"@disabled\",\"@name\",\"@aria-describedby\",\"@type\",\"@value\"],false,[\"input\",\"if\"]]",
    "moduleName": "rose/components/rose/form/input/input/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});