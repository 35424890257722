define("core/components/pending-confirmations/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _templateFactory, _component2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.confirm.pending as |confirmation|}}
    {{yield
      confirmation
      (fn this.accept confirmation)
      (fn this.deny confirmation)}}
  {{/each}}
  
  */
  {
    "id": "ZhxQnLs2",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"confirm\",\"pending\"]]],null]],null],null,[[[1,\"  \"],[18,2,[[30,1],[28,[37,3],[[30,0,[\"accept\"]],[30,1]],null],[28,[37,3],[[30,0,[\"deny\"]],[30,1]],null]]],[1,\"\\n\"]],[1]],null]],[\"confirmation\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"fn\"]]",
    "moduleName": "core/components/pending-confirmations/index.hbs",
    "isStrictMode": false
  });

  /**
   * A component that yields for each pending confirmation in the confirmations
   * service, passing the confirmation instance, a confirm action, and a dismiss
   * action. Useful for rendering confirm modals and awaiting user action.
   */
  let PendingConfirmationsComponent = (_class = class PendingConfirmationsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "confirm", _descriptor, this);
    }

    // =actions

    /**
     * Accepts the passed confirmation.
     * @param {Confirmation} confirmation
     * @return {Confirmation}
     */
    accept(confirmation) {
      confirmation.confirm();
      return confirmation;
    }
    /**
     * Denies the passed confirmation.
     * @param {Confirmation} confirmation
     * @return {Confirmation}
     */


    deny(confirmation) {
      confirmation.dismiss();
      return confirmation;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "confirm", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accept", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "accept"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deny", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deny"), _class.prototype)), _class);
  _exports.default = PendingConfirmationsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PendingConfirmationsComponent);
});